import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSurveyInformation from '../../common/hooks/useSurveyInformation';
import QuestionPageService from '../../service/questionPageService/QuestionPageService';
import RadioAnswer from '../radioAnswer/RadioAnswer';
import SelectAnswers from '../selectAnswers/SelectAnswers';
import SliderAnswer from '../silderAnswer/SliderAnswer';

const style = makeStyles((theme) => ({
  labelStyleDisabled: {
    maxWidth: '500px',
    width: '80%',
    textAlign: 'left',
    color: '#B1B0AF',
    '@media (max-width:1149px)': {
      textAlign: 'left',
      marginLeft: '0px',
    },
  },
  answerContainer: {
    maxWidth: '500px',
    width: '80%',
    textAlign: 'left',
    // marginLeft: '50px',
    '@media (max-width:1149px)': {
      textAlign: 'left',
      marginLeft: '0px',
    },
  },
}));

function QuestionAnswer({
  question,
  calculationVariables,
  setAnswerInCalculationVariables,
  renderQuestion,
}) {
  const classes = style();
  const [surveyInformation, surveyInformationDispatch] = useSurveyInformation();
  const { t } = useTranslation();

  const removePageCompletions = () => {
    QuestionPageService.removePageCompletions(
      question,
      surveyInformation,
      surveyInformationDispatch
    );
  };

  const questionAnswerSwitch = () => {
    if (question.questionConditions && !renderQuestion) {
      return <p className={classes.labelStyleDisabled}>{t('questionPages.notRelevant')}</p>;
    }
    switch (question.questionType) {
      case 'Dropdown':
        return (
          <SelectAnswers
            question={question}
            calculationVariable={calculationVariables[question.targetVariable]}
            setAnswerInCalculationVariables={setAnswerInCalculationVariables}
            removePageCompletions={removePageCompletions}
          ></SelectAnswers>
        );
      case 'Slider':
        return (
          <SliderAnswer
            question={question}
            calculationVariable={calculationVariables[question.targetVariable]}
            setAnswerInCalculationVariables={setAnswerInCalculationVariables}
          ></SliderAnswer>
        );
      case 'Radio':
        return (
          <RadioAnswer
            question={question}
            calculationVariable={calculationVariables[question.targetVariable]}
            setAnswerInCalculationVariables={setAnswerInCalculationVariables}
            removePageCompletions={removePageCompletions}
          ></RadioAnswer>
        );
      default:
        return <div></div>;
    }
  };

  return <div className={classes.answerContainer}>{questionAnswerSwitch()}</div>;
}

export default QuestionAnswer;
