import { ClickAwayListener, Tooltip, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useState } from 'react';

const style = makeStyles((_theme) => ({
  tooltipIcon: {
    color: '#d5dfef',
    fontSize: '1em',
  },
}));

export default function SmallDisplayTooltip({ tooltipTitle, tooltipClassName, ...props }) {
  const classes = style();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        className={tooltipClassName}
        style={props.style}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        title={tooltipTitle}
        placement="left-end"
        arrow
      >
        <InfoIcon className={classes.tooltipIcon} onClick={handleTooltipOpen} />
      </Tooltip>
    </ClickAwayListener>
  );
}
