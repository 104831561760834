import { makeStyles } from '@material-ui/core';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCalculationVariables from '../../common/hooks/useCalculationVariables';
import useGseConfiguration from '../../common/hooks/useGseConfiguration';
import QuestionConditionsService from '../../service/questionConditionsService/QuestionConditionsService';
import QuestionAnswer from '../questionAnswer/QuestionAnswer';
import QuestionLabelWithTooltip from '../questionTooltip/QuestionLabelWithTooltip';
import UserFeedback from '../userFeedback/UserFeedback';

const style = makeStyles((_theme) => ({
  questionContainer: {
    '@media (max-width: 749px)': {
      marginLeft: '15%',
      justifyContent: 'left',
    },
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'center',
    marginTop: '2%',
    columnGap: '100px',
  },
  answerFeedbackContainer: {
    '@media (max-width: 749px)': {
      marginLeft: '0',
    },
    display: 'flex',
    flex: '1 1 500px',
    marginLeft: '20px',
    // width: '50%',
    minWidth: '500px',
    maxWidth: '800px',
    flexWrap: 'wrap',
  },
  feedbackIcon: {
    fontSize: '50px',
    color: '#FFFFFF',
  },
}));

function Question({ question }) {
  const classes = style();
  const [calculationVariables, calculationVariablesDispatch] = useCalculationVariables();
  const [gseConfiguration, gseConfigurationDispatch] = useGseConfiguration();
  const [renderQuestion, setRenderQuestion] = useState(true);
  const { i18n } = useTranslation();
  const calledOnce = useRef();

  const setAnswerInCalculationVariables = useCallback(
    (answerText, answerValue, behavesLikeNoAnswer, targetVariable) => {
      calculationVariablesDispatch({
        type: 'setAnswerInCalculationVariables',
        payload: {
          targetVariable: targetVariable ?? question.targetVariable,
          answerText: answerText,
          answerValue: answerValue,
          behavesLikeNoAnswer: behavesLikeNoAnswer,
        },
      });
    },
    [question, calculationVariablesDispatch]
  );

  useEffect(() => {
    if (calledOnce.current) {
      QuestionConditionsService.updateConditionalQuestions(
        gseConfiguration.pageDefinitions,
        calculationVariables,
        gseConfigurationDispatch,
        setAnswerInCalculationVariables,
        i18n
      );
    }
    calledOnce.current = true;
  }, [
    calculationVariables,
    gseConfiguration.pageDefinitions,
    gseConfigurationDispatch,
    setAnswerInCalculationVariables,
    i18n,
  ]);

  useEffect(() => {
    if (!question.renderQuestion) {
      setRenderQuestion(false);
    } else {
      setRenderQuestion(true);
    }
  }, [question, calculationVariables]);

  return (
    <div className={classes.questionContainer}>
      <QuestionLabelWithTooltip question={question} />
      <div className={classes.answerFeedbackContainer}>
        <QuestionAnswer
          question={question}
          calculationVariables={calculationVariables}
          setAnswerInCalculationVariables={setAnswerInCalculationVariables}
          renderQuestion={renderQuestion}
        ></QuestionAnswer>
        <UserFeedback targetVariable={question.targetVariable}></UserFeedback>
      </div>
    </div>
  );
}

export default Question;
