import React, { useMemo, useState } from 'react';
import { getValueFromLocalStorageItemByKey } from '../../utils/LocalStorageUtils';

export const userDataContext = React.createContext([{}, () => {}]);

export default function UserDataContextProvider({ children }) {
  const [userData, setUserData] = useState({
    email: getValueFromLocalStorageItemByKey('userData', 'email') ?? '',
    applicationName: getValueFromLocalStorageItemByKey('userData', 'applicationName') ?? '',
    userName: getValueFromLocalStorageItemByKey('userData', 'userName') ?? '',
    companyName: getValueFromLocalStorageItemByKey('userData', 'companyName') ?? '',
    privateDataAgreement: false,
  });
  const userDataProviderValue = useMemo(() => [userData, setUserData], [userData]);

  return (
    <userDataContext.Provider value={userDataProviderValue}>{children}</userDataContext.Provider>
  );
}
