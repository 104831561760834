import axios from 'axios';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthentication from '../../common/hooks/useAuthentication';
import useSnackbar from '../../common/hooks/useSnackbar';

function AxiosInterception({ children }) {
  const [authentication, setAuthentication] = useAuthentication();
  const [, setSnackbarProps] = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    const reqInterceptor = (request) => {
      if (request && authentication.accessToken) {
        request.headers['Authorization'] = `Bearer ${authentication.accessToken}`;
      }
      return request;
    };

    const errInterceptor = (error) => {
      return Promise.reject(error);
    };

    const requestInterceptor = axios.interceptors.request.use(reqInterceptor, errInterceptor);
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
    };
  }, [authentication.accessToken]);

  useEffect(() => {
    const resInterceptor = (response) => {
      return response;
    };
    const errInterceptor = (error) => {
      if (error.response.status === 401) {
        setAuthentication({ isAuthenticated: false });
        setSnackbarProps({
          message: t('alerts.unauthorize'),
          severity: 'error',
          duration: 6000,
        });
      }
      if (error.response.status >= 500) {
        setSnackbarProps({
          message: t('exceptions.defaultException'),
          severity: 'error',
          duration: 6000,
        });
      }
      return Promise.reject(error);
    };
    const responseInterceptor = axios.interceptors.response.use(resInterceptor, errInterceptor);
    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [t, setAuthentication, setSnackbarProps]);

  return children;
}

export { AxiosInterception };
