import axios from 'axios';

export const getAuthenticationToken = (applicationCode, redirectUri) => {
  const body = {
    code: applicationCode,
    redirectUri: redirectUri,
  };
  const options = {
    headers: { 'Content-Type': 'application/json' },
  };
  return axios.post(`/api/v1/authenticate`, body, options);
};
