import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Slider from '@material-ui/core/Slider';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorBoundary from '../errorBoundary/ErrorBoundary';

const style = makeStyles((theme) => ({
  root: {
    color: '#7184A9',
  },
  thumb: { '&:hover ': { color: '#FFFFFF' } },
  active: { color: '#FFFFFF' },
  valueLabel: { color: 'transparent', fontSize: '0.8em' },
  slider: { marginTop: '15px' },
}));

function SliderAnswer({ question, setAnswerInCalculationVariables, calculationVariable }) {
  const [sliderValue, setSliderValue] = useState(0);
  const classes = style();
  const { i18n } = useTranslation();

  const valueLabelFormat = (value) => {
    return `${
      question.possibleAnswers?.length
        ? question.possibleAnswers[value]?.answerText[i18n.resolvedLanguage] ??
          question.possibleAnswers[value].answerValue
        : value
    }`;
  };

  const handleChange = (event, newValue) => {
    let contextValue = question.possibleAnswers[newValue]?.answerValue ?? newValue;
    let answerText = question.possibleAnswers?.length
      ? question.possibleAnswers[newValue]?.answerText ??
        question.possibleAnswers[newValue].answerValue
      : 'SliderInput';
    setSliderValue(newValue);
    setAnswerInCalculationVariables(answerText, contextValue, false);
  };

  const setSliderValueIndexByAnswerText = useCallback(() => {
    question.possibleAnswers.some((possibleAnswer, possibleAnswerIndex) => {
      if (
        calculationVariable.answerText[i18n.resolvedLanguage]
          ? calculationVariable.answerText[i18n.resolvedLanguage] ===
            possibleAnswer.answerText[i18n.resolvedLanguage]
          : calculationVariable.answerValue === possibleAnswer.answerValue
      ) {
        setSliderValue(possibleAnswerIndex);
        return true;
      }
      return false;
    });
  }, [
    calculationVariable.answerText,
    calculationVariable.answerValue,
    i18n.resolvedLanguage,
    question.possibleAnswers,
  ]);

  useEffect(() => {
    if (question.possibleAnswers?.length) {
      setSliderValueIndexByAnswerText();
    } else {
      setSliderValue(calculationVariable.answerValue);
    }
  }, [
    setSliderValueIndexByAnswerText,
    question.possibleAnswers?.length,
    calculationVariable.answerValue,
  ]);

  return (
    <FormControl required style={{ width: '70%' }}>
      <ErrorBoundary>
        <Slider
          className={classes.slider}
          classes={{
            root: classes.root,
            active: classes.active,
            thumb: classes.thumb,
            valueLabel: classes.valueLabel,
          }}
          getAriaValueText={valueLabelFormat}
          valueLabelFormat={valueLabelFormat}
          value={sliderValue}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="on"
          step={question.step !== 0 ? question.step : 1}
          marks
          min={question.min}
          max={question.max !== 0 ? question.max : question.possibleAnswers.length - 1}
          onChange={handleChange}
        ></Slider>
      </ErrorBoundary>
    </FormControl>
  );
}

export default SliderAnswer;
