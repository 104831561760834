import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const RestrictedRoute = ({ surveyWasStarted, ...rest }) => {
  if (!surveyWasStarted) {
    return <Redirect to="/home" />;
  }
  return <Route {...rest} />;
};

export default RestrictedRoute;
