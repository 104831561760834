import { useContext } from 'react';
import { calculationVariablesContext } from '../providers/CalculationContextProvider';

function useCalculationVariables() {
  const [calculationVariables, calculationVariablesDispatch] = useContext(
    calculationVariablesContext
  );
  return [calculationVariables, calculationVariablesDispatch];
}

export default useCalculationVariables;
