import axios from 'axios';

export const getCalculationResults = () => {
  const options = {
    headers: {
      Accept: 'application/json',
    },
  };
  return axios.get(`/api/v1/calculationResults`, options);
};
