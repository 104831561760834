import { makeStyles } from '@material-ui/core/styles';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCalculationVariables from '../../common/hooks/useCalculationVariables';

const useStyles = makeStyles((theme) => ({
  textAreaContainer: {
    flexBasis: '150px',
    minWidth: '150px',
  },
  textAreaStyle: {
    color: '#000',
    backgroundColor: '#d5dfef',
    border: 'none',
    borderRadius: '5px',
    fontFamily: theme.typography.fontFamily,
    marginTop: '20px',
    width: '145px',
    minWidth: '100px',
    minHeight: '17px',
    '&::placeholder': {
      color: '#51504E',
      opacity: 0.8,
    },
    '@media (max-width: 1350px)': {
      merginLeft: '0px',
      marginBottom: '30px',
    },
  },
}));

function UserFeedback({ targetVariable }) {
  const classes = useStyles();
  const [calculationVariables] = useCalculationVariables();
  const [userFeedback, setUserFeedback] = useState('');
  const { t } = useTranslation();

  const setUserFeedbackInContext = (userFeedbackValue) => {
    calculationVariables[targetVariable].userFeedback = userFeedbackValue;
  };

  useEffect(() => {
    if (calculationVariables[targetVariable]?.userFeedback !== '') {
      setUserFeedback(calculationVariables[targetVariable].userFeedback);
    }
  }, [calculationVariables, targetVariable]);

  return (
    <div className={classes.textAreaContainer}>
      <TextareaAutosize
        className={classes.textAreaStyle}
        value={userFeedback}
        aria-label="empty textarea"
        placeholder={t('questionPages.questionFeedback')}
        onChange={(userFeedbackValue) => {
          setUserFeedback(userFeedbackValue.target.value);
        }}
        onBlur={(userFeedbackValue) => {
          setUserFeedbackInContext(userFeedbackValue.target.value);
        }}
      />
    </div>
  );
}

export default UserFeedback;
