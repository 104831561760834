import { makeStyles } from '@material-ui/core';
import React from 'react';

const style = makeStyles((theme) => ({
  header: {
    color: '#fefefe',
    margin: 0,
  },
}));

function Header() {
  const classes = style();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: '30px' }}>
      <h2 className={classes.header}>Green Software Expertyzer</h2>
    </div>
  );
}

export default Header;
