import axios from 'axios';

export const getCalculatedGrade = (calculationVariables, configUtc, userData, uuid, language) => {
  const sgeCalculationControllerRequestBody = Object.assign({}, calculationVariables);
  const answeredQuestions = Array.from(Object.values(sgeCalculationControllerRequestBody));

  const body = {
    answeredQuestions: answeredQuestions,
    configUtc: configUtc,
    userData: userData,
    uuid: uuid,
  };

  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': language,
    },
  };

  return axios.post(`/api/v1/calculatesge`, body, options);
};
