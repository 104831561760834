import { IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useUserData from '../../common/hooks/useUserData';
import { properties } from '../../properties';
import CalenderCircle from '../slideButtons/calendar-circle-user-light.svg';
import chatButtonIcon from '../slideButtons/comments-question-light.svg';
import UpgradeIcon from '../slideButtons/Ebene_1.svg';

const style = makeStyles((_theme) => ({
  desktopShowButton: {
    position: 'absolute',
    right: '0px',
    top: '132px',
    display: 'flex',
    flexDirection: 'row',
    width: '231px',
    height: '50px',
    background: '#C2CADB 0% 0% no-repeat padding-box',
    boxShadow: '0px 3px 6px #00000029',
    marginTop: '14px',
    borderRadius: '25px 0px 0px 25px',

    '&:hover': {
      backgroundColor: '#93f100',
    },
  },

  desktopbuttonText: {
    font: 'normal normal 300 1em/1.1em Calibri;',
    textAlign: 'left',
    color: '#2D4E88',

    marginTop: '0px',
    display: 'block',
  },

  buttonFullText: {
    fontWeight: '900',
    display: 'inline',
    fontFamily: 'Calibri Bold',
  },

  buttonIcon: {
    marginLeft: '10px',
    marginRight: '15px',
    height: '30px',
    width: '30px',
    marginTop: '2px',

    color: '#7184A9',
  },

  desktopChatButton: {
    height: '50px',
    width: '50px',
    backgroundColor: '#C2CADB',
    borderRadius: '100px 100px 100px 100px',
    position: 'relative',
    right: '-46%',
    bottom: '41px',
    color: '#C2CADB',
    '&:hover': {
      backgroundColor: '#93f100',
    },
  },

  chatButtonIcon: {
    color: '#7184A9',
  },
}));

function SlideButtonsDesktop({ gsdUrl }) {
  const classes = style();
  const { t } = useTranslation();
  const [userData] = useUserData();

  return (
    <div>
      <div>
        <IconButton
          id="contactBtn"
          className={classes.desktopShowButton}
          onClick={(e) => {
            const newWindow = window.open(properties.gsdConsultationUrl);
            newWindow.opener = null;
            e.preventDefault();
          }}
        >
          <img alt="" src={CalenderCircle} className={classes.buttonIcon} />
          <div className={classes.desktopbuttonText}>{t('buttons.consultation')}</div>
        </IconButton>
        {!userData.hasPremiumRole && (
          <div>
            <IconButton
              id="upgradeBtn"
              className={classes.desktopShowButton}
              style={{ top: '195px' }}
              onClick={() => {
                const newWindow = window.open(gsdUrl);
                newWindow.opener = null;
              }}
            >
              <img alt="" src={UpgradeIcon} className={classes.buttonIcon} />
              <div className={classes.desktopbuttonText}>
                {t('buttons.membership1')}{' '}
                <p className={classes.buttonFullText}>{t('buttons.membership2')}</p>{' '}
                {t('buttons.membership3')}
              </div>
            </IconButton>
          </div>
        )}
      </div>
      <IconButton
        className={classes.desktopChatButton}
        onClick={(e) => {
          window.location =
            'mailto:green@syngenio.de?&subject=' +
            t('mailTo.contactButtonSubject') +
            '&body=' +
            t('mailTo.contactButtonBody');
          e.preventDefault();
        }}
      >
        <img alt="" src={chatButtonIcon} className={classes.chatButtonIcon} />
      </IconButton>
    </div>
  );
}
export default SlideButtonsDesktop;
