import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const style = makeStyles((theme) => ({
  inputLable: {
    color: '#FFFFFF',
    fontSize: '0.8em',
  },
  inputLableShrink: {
    transform: 'translate(0, 1.5px) scale(0.9)',
  },
  formControl: {
    width: '70%',
    minWidth: '200px',
    color: 'green',
    '& .MuiInputBase-root': {
      fontSize: '1em',
    },
    '&:hover': {
      '& .MuiSvgIcon-root': {
        color: '#FFFFFF',
      },
    },
    '& .MuiInput-underline': {
      '&:before, &:after': {
        borderBottom: '1px solid #7184A9',
      },
      '&:hover, &:hover:not(.Mui-disabled):before': {
        borderBottom: '1px solid #FFFFFF',
      },
    },
  },
  icon: {
    color: '#7184A9',
    fontSize: '1em',
  },
  select: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontSize: '0.8em',
  },
  menuItem: {
    fontSize: '0.8em',
    justifyContent: 'center',
  },
}));

function SelectAnswers({
  question,
  setAnswerInCalculationVariables,
  calculationVariable,
  removePageCompletions,
}) {
  const [value, setValue] = useState('');
  const classes = style();
  const { t, i18n } = useTranslation();

  const getNumericValue = (stringValue) => {
    let numericValue;
    question?.possibleAnswers.some((answer) => {
      if (
        answer.answerValue === stringValue ||
        (answer.answerText && answer.answerText[i18n.resolvedLanguage] === stringValue)
      ) {
        numericValue = answer.answerValue;
        return true;
      }
      return false;
    });
    return numericValue;
  };

  const getBehavesLikeNoAnswer = (stringValue) => {
    for (const language of i18n.languages) {
      if (i18n.getFixedT(language)('questionPages.noAnswer') === stringValue) {
        return true;
      }
    }
    for (const answer of question.possibleAnswers) {
      if (
        stringValue === answer.answerValue ||
        stringValue === answer.answerText[i18n.resolvedLanguage]
      ) {
        return answer.behavesLikeNoAnswer;
      }
    }
    return false;
  };

  const getAnswerText = (stringValue) => {
    for (const answer of question.possibleAnswers) {
      if (
        answer.answerValue === stringValue ||
        (answer.answerText && answer.answerText[i18n.resolvedLanguage] === stringValue)
      ) {
        const answerResult = answer.answerText ? answer.answerText : answer.answerValue;
        return answerResult;
      }
    }
    let noAnswer = {};
    i18n.languages.forEach((language) => {
      noAnswer = { ...noAnswer, [language]: i18n.getFixedT(language)('questionPages.noAnswer') };
    });
    return noAnswer;
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    setAnswerInCalculationVariables(
      getAnswerText(event.target.value),
      getNumericValue(event.target.value) ?? question.defaultValue,
      getBehavesLikeNoAnswer(event.target.value) ?? false
    );
    removePageCompletions();
  };

  useEffect(() => {
    if (calculationVariable?.answerText && calculationVariable.answerText[i18n.resolvedLanguage]) {
      setValue(calculationVariable.answerText[i18n.resolvedLanguage]);
    } else if (calculationVariable?.answerText && calculationVariable.answerText.num) {
      setValue(calculationVariable.answerText.num);
    } else if (calculationVariable.answerValue != null) {
      setValue(calculationVariable.answerValue);
    }
  }, [
    calculationVariable.answerText,
    i18n.resolvedLanguage,
    setAnswerInCalculationVariables,
    question.defaultValue,
    calculationVariable.answerValue,
  ]);

  return (
    <FormControl className={classes.formControl} required>
      <InputLabel
        id="select-options"
        classes={{ root: classes.inputLable, shrink: classes.inputLableShrink }}
      >
        {t('questionPages.chooseOption')}
      </InputLabel>
      <Select
        style={{ paddingTop: '2%' }}
        classes={{
          select: classes.select,
          icon: classes.icon,
          nativeInput: classes.nativeInput,
        }}
        IconComponent={KeyboardArrowDownIcon}
        lableid="select-options"
        value={value}
        onChange={handleChange}
      >
        {question.possibleAnswers.map((answer) => (
          <MenuItem
            className={classes.menuItem}
            key={
              answer.answerText && answer.answerText[i18n.resolvedLanguage]
                ? answer.answerText[i18n.resolvedLanguage]
                : answer.answerValue
            }
            value={
              answer.answerText && answer.answerText[i18n.resolvedLanguage]
                ? answer.answerText[i18n.resolvedLanguage]
                : answer.answerValue
            }
          >
            {answer.answerText && answer.answerText[i18n.resolvedLanguage]
              ? answer.answerText[i18n.resolvedLanguage]
              : answer.answerValue}
          </MenuItem>
        ))}
        {question.noAnswerIsPossible === true && (
          <MenuItem className={classes.menuItem} value={t('questionPages.noAnswer')}>
            {t('questionPages.noAnswer')}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
}

export default SelectAnswers;
