import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useAuthentication from '../../common/hooks/useAuthentication';
import useCalculationVariables from '../../common/hooks/useCalculationVariables';
import useGseConfiguration from '../../common/hooks/useGseConfiguration';
import useSurveyInformation from '../../common/hooks/useSurveyInformation';
import CalculationResultHistory from '../calculationResultHistory/CalculationResultHistory';
import Header from '../header/Header';
import RoundButton from '../roundButton/RoundButton';

function CalculationResultHistoryPage() {
  let history = useHistory();
  const [, calculationVariablesDispatch] = useCalculationVariables();
  const [surveyInformation, surveyInformationDispatch] = useSurveyInformation();
  const [gseConfiguration] = useGseConfiguration();
  const { t, i18n } = useTranslation();
  const [authentication] = useAuthentication();

  const handleResetButtonClick = () => {
    calculationVariablesDispatch({
      type: 'resetCalculationVariableState',
      payload: gseConfiguration,
    });
    surveyInformationDispatch({ type: 'resetSurveyInformation', payload: gseConfiguration });
    localStorage.setItem('surveyInformation', JSON.stringify(surveyInformation));
    localStorage.removeItem('calculationVariables');
    history.push('/page/0');
  };

  useEffect(() => {
    if (history && !authentication.isAuthenticated) {
      history.replace('/page/0');
    }
  }, [authentication.isAuthenticated, history]);

  useEffect(() => {
    calculationVariablesDispatch({
      type: 'setQuestionLabelsLanguage',
      payload: {
        locale: i18n.resolvedLanguage,
        pageDefinitions: gseConfiguration?.pageDefinitions,
      },
    });
  }, [calculationVariablesDispatch, gseConfiguration?.pageDefinitions, i18n.resolvedLanguage]);

  return (
    <div>
      <Header />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <CalculationResultHistory> </CalculationResultHistory>
        <div
          style={{
            marginTop: '30px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          <RoundButton
            style={{ marginLeft: '10px', marginRight: '10px', marginTop: '20px' }}
            fontColor="#C2CADB"
            color="secondary"
            variant="outlined"
            hoverFontColor="#51504E"
            hoverBackgroundColor="#C2CADB"
            onClick={() => {
              history.push('/home');
            }}
          >
            {t('buttons.back')}
          </RoundButton>
          {localStorage.getItem('calculationVariables') && (
            <RoundButton
              style={{ marginLeft: '5px', marginRight: '5px', marginTop: '20px' }}
              maxMediaDisplayWidth="311px"
              smallDisplayHeight="3.3em"
              smallDisplayWidth="13em"
              fontColor="#C2CADB"
              color="secondary"
              variant="outlined"
              hoverFontColor="#51504E"
              hoverBackgroundColor="#C2CADB"
              onClick={handleResetButtonClick}
            >
              {t('buttons.restart')}
            </RoundButton>
          )}
          <RoundButton
            style={{ marginLeft: '10px', marginRight: '10px', marginTop: '20px' }}
            color="primary"
            fontColor="#51504E"
            hoverFontColor="#FFFFFF"
            variant="contained"
            onClick={() => {
              localStorage.setItem('surveyInformation', JSON.stringify(surveyInformation));
              history.push('/page/' + surveyInformation.actualPage);
            }}
          >
            {t('buttons.next')}
          </RoundButton>
        </div>
      </div>
    </div>
  );
}

export default CalculationResultHistoryPage;
