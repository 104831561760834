import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Header from '../header/Header';
import RoundButton from '../roundButton/RoundButton';

const style = makeStyles((theme) => ({
  textStyle: {
    color: '#FFFFFF',
  },
  question: {
    marginTop: '20px',
    textAlign: 'left',
    marginBottom: 0,
    fontWeight: '200',
    whiteSpace: 'pre-line',
  },
  answer: {
    marginTop: '3px',
    textAlign: 'left',
    whiteSpace: 'pre-line',
  },
}));

function IntroductionPage() {
  const classes = style();
  const { t } = useTranslation();
  let history = useHistory();

  return (
    <div>
      <Header></Header>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ maxWidth: '1300px', width: '85%' }} className={classes.textStyle}>
          <h3 className={classes.question}>{t('introductionPage.questions.label1')}</h3>
          <p className={classes.answer}> {t('introductionPage.questions.answer1')}</p>
          <h3 className={classes.question}>{t('introductionPage.questions.label2')}</h3>
          <p className={classes.answer}> {t('introductionPage.questions.answer2')}</p>
          <h3 className={classes.question}>{t('introductionPage.questions.label3')}</h3>
          <p className={classes.answer}> {t('introductionPage.questions.answer3')}</p>
          <h3 className={classes.question}>{t('introductionPage.questions.label4')}</h3>
          <p className={classes.answer}> {t('introductionPage.questions.answer4')}</p>
          <h3 className={classes.question}>{t('introductionPage.questions.label5')}</h3>
          <p className={classes.answer}> {t('introductionPage.questions.answer5')}</p>
          <h3 className={classes.question}>{t('introductionPage.questions.label6')}</h3>
          <p className={classes.answer}> {t('introductionPage.questions.answer6')}</p>
          <h3 className={classes.question}>{t('introductionPage.questions.label7')}</h3>
          <p className={classes.answer}> {t('introductionPage.questions.answer7')}</p>
        </div>
      </div>
      <RoundButton
        style={{ marginTop: '2%' }}
        color="secondary"
        fontColor="#C2CADB"
        variant="outlined"
        hoverBackgroundColor="#C2CADB"
        hoverFontColor="#51504E"
        onClick={() => {
          history.push('/home');
        }}
      >
        {t('buttons.back')}
      </RoundButton>
    </div>
  );
}

export default IntroductionPage;
