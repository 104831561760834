export const properties = {
  gsdMemberschipUrl: 'https://www.greensoftwaredesign.com/gsd-green-software-design-community/',
  gsdConsultationUrl: 'https://www.greensoftwaredesign.com/gsd-rueckruf-vereinbaren/',
  gsdAuthenticateUrl: 'https://www.greensoftwaredesign.com/oauth/authorize',
  clientId: '61d05260867b7491b40aaa8d2dd5afcae4339f2e24afcdace2285b763b21',
  redirectUri: `${window.location.protocol}//${window.location.host}/home`,
  syngenioUrl: 'https://syngenio.com',
  gsdPrivacyPolicyDe: 'https://www.greensoftwaredesign.com/datenschutz/',
  gsdPrivacyPolicyEn: 'https://www.greensoftwaredesign.com/en/privacy-policy/',
  gsdImprintDe: 'https://www.greensoftwaredesign.com/impressum/',
  gsdImprintEn: 'https://www.greensoftwaredesign.com/en/imprint/',
  syngenioGtc: 'https://syngenio.com/wp-content/uploads/2020/07/Syngenio-AGB-2020.pdf',
  gsdLabelDe: 'https://www.greensoftwaredesign.com/green-software-design-label/',
  gsdLabelEn: 'https://www.greensoftwaredesign.com/en/green-software-design-label/',
};
