import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { properties } from '../../properties';

const style = makeStyles((theme) => ({
  container: {
    display: 'flex',
    paddingTop: '1em',
  },
  checkbox: {
    paddingTop: '5px',
    alignSelf: 'flex-start',
    marginLeft: '0.3em',
    '& svg': {
      color: '#C2CADB',
      fontSize: '1.2em',
    },
  },
  text: {
    margin: 0,
    textAlign: 'left',
    fontSize: '0.8em',
    color: '#FFFFFF',
  },
  dataprivacyLink: {
    color: theme.palette.primary.main,
    '&:hover': {
      opacity: 0.7,
    },
  },
}));

export default function DataprivacyCheckbox({ setUserData }) {
  const classes = style();
  const [checked, setChecked] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setUserData((prevState) => ({
      ...prevState,
      privateDataAgreement: checked,
    }));
  }, [checked, setUserData]);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className={classes.container}>
      <Checkbox
        className={classes.checkbox}
        checked={checked}
        onChange={handleCheckboxChange}
      ></Checkbox>
      <p className={classes.text}>
        {t('dataprivacy.text')}
        <a
          href={
            i18n.resolvedLanguage === 'en'
              ? properties.gsdPrivacyPolicyEn
              : properties.gsdPrivacyPolicyDe
          }
          className={classes.dataprivacyLink}
          target="_blank"
          rel="noreferrer"
        >
          {t('dataprivacy.link')}
        </a>
        .
      </p>
    </div>
  );
}
