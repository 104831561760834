import { IconButton, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useUserData from '../../common/hooks/useUserData';
import { properties } from '../../properties';
import CalenderCircle from '../slideButtons/calendar-circle-user-light.svg';
import chatButtonIcon from '../slideButtons/comments-question-light.svg';
import UpgradeIcon from '../slideButtons/Ebene_1.svg';

const style = makeStyles((_theme) => ({
  buttonFullText: {
    fontWeight: '900',
    display: 'inline',
    fontFamily: 'Calibri Bold',
  },

  buttonIcon: {
    height: '30px',
    width: '30px',
    color: '#7184A9',
  },

  chatButtonIcon: {
    color: '#7184A9',
  },

  contactButtonsDiv: {
    display: 'flex',
    width: '90%',
    justifyContent: 'space-evenly',
    maxWidth: '1000px',
    margin: 'auto',
    marginTop: '20px',
    marginBottom: '30px',
  },

  mobileShowButton: {
    height: '50px',
    width: '50px',
    bottom: '10px',
    marginTop: '100px',
    backgroundColor: '#C2CADB',
    borderRadius: '100px 100px 100px 100px',

    '&:hover': {
      backgroundColor: '#93f100',
    },
  },

  mobilebuttonText: {
    width: '100%',
    font: 'normal normal 300 1em/1.1em Calibri;',
    textAlign: 'center',
    color: '#C2CADB',

    marginTop: '0px',
    display: 'block',
  },
}));

function SlideButtonsMobile({ gsdUrl }) {
  const classes = style();
  const { t } = useTranslation();
  const [userData] = useUserData();

  return (
    <div className={classes.contactButtonsDiv}>
      <div>
        <IconButton
          id="contactBtn"
          className={classes.mobileShowButton}
          onClick={(e) => {
            const newWindow = window.open(properties.gsdConsultationUrl);
            newWindow.opener = null;
            e.preventDefault();
          }}
        >
          <img alt="" src={CalenderCircle} className={classes.buttonIcon} />
        </IconButton>
        <p className={classes.mobilebuttonText}>{t('buttons.consultation')}</p>
      </div>
      {!userData.hasPremiumRole && (
        <div id="btnUpgradeToFull">
          <IconButton
            id="upgradeBtn"
            className={classes.mobileShowButton}
            onClick={() => {
              const newWindow = window.open(gsdUrl);
              newWindow.opener = null;
            }}
          >
            <img alt="" src={UpgradeIcon} className={classes.buttonIcon} />
          </IconButton>
          <div className={classes.mobilebuttonText}>
            {t('buttons.membership1')}{' '}
            <p className={classes.buttonFullText}>{t('buttons.membership2')}</p>{' '}
            {t('buttons.membership3')}
          </div>
        </div>
      )}
      <div>
        <IconButton
          className={classes.mobileShowButton}
          onClick={(e) => {
            window.location =
              'mailto:green@syngenio.de?&subject=' +
              t('mailTo.contactButtonSubject') +
              '&body=' +
              t('mailTo.contactButtonBody');
            e.preventDefault();
          }}
        >
          <img alt="" src={chatButtonIcon} className={classes.chatButtonIcon} />
        </IconButton>
        <div className={classes.mobilebuttonText}>{t('buttons.chatEmail')}</div>
      </div>
    </div>
  );
}
export default SlideButtonsMobile;
