import React from 'react';
import Logo from '../../Logo.png';
import { makeStyles } from '@material-ui/core';

const style = makeStyles(() => ({
  logo: {
    width: '150px',
    height: '24px',
  },
  logoPin: {
    margin: '36px',
    position: 'relative',
    left: 0,
    bottom: 0,
  },
}));

function SyngenioLogo() {
  const classes = style();

  return (
    <div className={classes.logoPin}>
      <img className={classes.logo} src={Logo} alt="Logo"></img>
    </div>
  );
}

export default SyngenioLogo;
