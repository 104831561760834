import { makeStyles, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import SmallDisplayTooltip from '../tooltips/SmallDisplayTooltip';
import WideDisplayTooltip from '../tooltips/WideDisplayTooltip';

const style = makeStyles((_theme) => ({
  labelSmallDisplayContainer: {
    display: 'flex',
    maxWidth: '500px',
    width: '80%',
  },
  labelLargeDisplayContainer: {
    display: 'flex',
    maxWidth: '550px',
    width: '80%',
  },
  labelStyle: {
    width: '80%',
    textAlign: 'left',
    color: '#FFFFFF',
  },
  labelStyleDisabled: {
    maxWidth: '500px',
    width: '80%',
    textAlign: 'left',
    color: '#B1B0AF',
  },
  tooltip: {
    fontSize: 'medium',
    '@media (max-width: 749px)': {
      marginLeft: '-17px',
    },
  },
  tooltipIcon: {
    color: '#d5dfef',
    fontSize: '1em',
  },
}));

function QuestionLabelWithTooltip({ question }) {
  const classes = style();
  const matches = useMediaQuery('(max-width:1200px)');
  const { i18n } = useTranslation();

  if (question.infotext && matches) {
    return (
      <div className={classes.labelSmallDisplayContainer}>
        <SmallDisplayTooltip
          tooltipClassName={classes.tooltip}
          tooltipTitle={question.infotext[i18n.resolvedLanguage]}
        />
        <p className={classes.labelStyle}>{question.questionLabel[i18n.resolvedLanguage]}</p>
      </div>
    );
  } else if (question.infotext && !matches) {
    return (
      <div className={classes.labelLargeDisplayContainer}>
        <WideDisplayTooltip tooltipTitle={question.infotext[i18n.resolvedLanguage]} />
        <p className={classes.labelStyle}>{question.questionLabel[i18n.resolvedLanguage]}</p>
      </div>
    );
  }
  return (
    <div className={classes.labelSmallDisplayContainer}>
      <p className={classes.labelStyle}>{question.questionLabel[i18n.resolvedLanguage]}</p>
    </div>
  );
}

export default QuestionLabelWithTooltip;
