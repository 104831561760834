import { getPageDefinition } from '../../common/api/configSge';

const GseConfigurationService = {
  fetchGseConfiguration: function (
    licenseType,
    gseConfigurationDispatch,
    setSnackbarProps,
    t,
    setLoadingGseConfiguration
  ) {
    getPageDefinition(licenseType)
      .then((resp) => {
        gseConfigurationDispatch({ type: 'setGseConfiguration', payload: resp.data });
        setLoadingGseConfiguration(false);
      })
      .catch((error) => {
        setLoadingGseConfiguration(false);
        if (
          error.response.status >= 400 &&
          error.response.status !== 401 &&
          error.response.status < 500
        ) {
          gseConfigurationDispatch({ type: 'setGseConfiguration', payload: null });
          setSnackbarProps({
            message: t(`exceptions.${error.response.data.message}`),
            severity: 'error',
            duration: 10000,
          });
        }
      });
  },
};

export default GseConfigurationService;
