import _ from 'lodash';
import React, { useEffect, useReducer, useRef } from 'react';
import { getValueFromLocalStorageItemByKey } from '../../utils/LocalStorageUtils';
import useGseConfiguration from '../hooks/useGseConfiguration';
import { useMemo } from 'react';

function init(gseConfiguration) {
  let surveyInformation = {};
  if (gseConfiguration) {
    surveyInformation = {
      renderedPages: {},
      actualPage: 0,
      gseConfigUtc: gseConfiguration.configUtc,
    };
  }
  return surveyInformation;
}

function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case 'setRenderedPages':
      if (!_.isEqual(state.renderedPages, payload.renderedPages)) {
        return {
          ...state,
          renderedPages: payload.renderedPages,
        };
      }
      return state;
    case 'setActualPage':
      return {
        ...state,
        actualPage: payload.actualPage,
      };
    case 'setPageConditions':
      return {
        ...state,
        renderedPages: {
          ...state.renderedPages,
          [payload.page]: {
            ...state.renderedPages[payload.page],
            pageConditions: payload.pageConditions,
          },
        },
      };
    case 'setPageIsCompleted':
      if (payload.isCompleted !== state.renderedPages[payload.page].isCompleted) {
        return {
          ...state,
          renderedPages: {
            ...state.renderedPages,
            [payload.page]: {
              ...state.renderedPages[payload.page],
              isCompleted: payload.isCompleted,
            },
          },
        };
      }
      return state;
    case 'resetSurveyInformation':
      return init(payload);
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
}

export const surveyInformationContext = React.createContext();

export default function SurveyInformationContextProvider({ children }) {
  const [gseConfiguration] = useGseConfiguration();
  const [surveyInformation, surveyInformationDispatch] = useReducer(
    reducer,
    gseConfiguration,
    init
  );
  const calledOnce = useRef();

  const surveyInformationProviderValue = useMemo(
    () => [surveyInformation, surveyInformationDispatch],
    [surveyInformation]
  );

  useEffect(() => {
    if (!calledOnce.current && gseConfiguration) {
      if (
        gseConfiguration.configUtc !==
        getValueFromLocalStorageItemByKey('surveyInformation', 'gseConfigUtc')
      ) {
        localStorage.removeItem('calculationVariables');
        localStorage.removeItem('surveyInformation');
      }
      surveyInformationDispatch({
        type: 'setRenderedPages',
        payload: {
          renderedPages:
            getValueFromLocalStorageItemByKey('surveyInformation', 'renderedPages') ??
            surveyInformation.renderedPages,
        },
      });
      surveyInformationDispatch({
        type: 'setActualPage',
        payload: {
          actualPage:
            getValueFromLocalStorageItemByKey('surveyInformation', 'actualPage') ??
            surveyInformation.actualPage,
        },
      });
    }
    calledOnce.current = true;
  }, [surveyInformation.renderedPages, surveyInformation.actualPage, gseConfiguration]);

  return (
    <surveyInformationContext.Provider value={surveyInformationProviderValue}>
      {children}
    </surveyInformationContext.Provider>
  );
}
