import { makeStyles, Tooltip } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import useAuthentication from '../../common/hooks/useAuthentication';
import useCalculationVariables from '../../common/hooks/useCalculationVariables';
import useSurveyInformation from '../../common/hooks/useSurveyInformation';
import RoundButton from '../roundButton/RoundButton';

const style = makeStyles((theme) => ({
  stepper: {
    justifyContent: 'space-evenly',
    flex: '1 1 auto',
    backgroundColor: 'transparent',
  },
  stepButton: {
    margin: 0,
    padding: 0,
  },
  step: {
    paddingLeft: '1px',
    paddingRight: '1px',
    width: '8px',
  },
  stepHorizontal: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  activeIcon: {
    color: '#FFFFFF !important',
    fontSize: '12px !important',
  },
  completedIcon: {
    color: theme.palette.primary + '!important',
    fontSize: '12px !important',
  },
  stepperIcon: {
    color: '#FFFFFF !important',
    opacity: '0.3',
    fontSize: '12px !important',
    '& .MuiStepIcon-text': {
      fontSize: '0px',
    },
  },
  tooltip: {
    fontSize: '0.5em',
  },
}));

function ProgressBar({ maxPage, withNavigationButton, withStepButtons, pageDefinitions }) {
  const classes = style();
  const [surveyInformation, surveyInformationDispatch] = useSurveyInformation();
  const [calculationVariables] = useCalculationVariables();
  let history = useHistory();
  const { t, i18n } = useTranslation();
  const [authentication] = useAuthentication();

  useEffect(() => {
    if (history.action === 'POP') {
      let pathname = history.location.pathname;
      let step = parseInt(pathname.substring(pathname.lastIndexOf('/') + 1));
      if (!Number.isNaN(step) && pageDefinitions[step].renderPage) {
        surveyInformationDispatch({ type: 'setActualPage', payload: { actualPage: step } });
        history.push('/page/' + step);
      } else if (!Number.isNaN(step) && !pageDefinitions[step].renderPage) {
        history.go(1);
      }
    }
  }, [history, surveyInformationDispatch, pageDefinitions, surveyInformation.actualPage]);

  useEffect(() => {
    localStorage.setItem('calculationVariables', JSON.stringify(calculationVariables));
    localStorage.setItem('surveyInformation', JSON.stringify(surveyInformation));
  }, [calculationVariables, surveyInformation]);

  const totalSteps = () => {
    return maxPage;
  };

  const allStepsCompleted = () => {
    return Object.keys(surveyInformation.renderedPages).every(
      (renderedPage) => surveyInformation.renderedPages[renderedPage].isCompleted
    );
  };

  const isLastStep = () => {
    return surveyInformation.actualPage === totalSteps();
  };

  const handleStep = (step) => () => {
    surveyInformationDispatch({ type: 'setActualPage', payload: { actualPage: step } });
    history.push('/page/' + step);
  };

  const handleBack = () => {
    let newActiveStep = surveyInformation.actualPage;
    let prevPage = 0;
    Object.keys(surveyInformation.renderedPages).some((renderedPage) => {
      if (parseInt(renderedPage) === newActiveStep) {
        newActiveStep = parseInt(prevPage);
        return true;
      }
      prevPage = renderedPage;
      return false;
    });
    surveyInformationDispatch({ type: 'setActualPage', payload: { actualPage: newActiveStep } });
    history.push('/page/' + newActiveStep);
  };

  const handleFirstPageBack = () => {
    if (!authentication.isAuthenticated) {
      history.push('/home');
    } else {
      history.push('/calculationResultHistoryPage');
    }
  };

  const handleNext = () => {
    let newActiveStep = surveyInformation.actualPage;
    Object.keys(surveyInformation.renderedPages).some((renderedPage) => {
      if (renderedPage > newActiveStep) {
        newActiveStep = parseInt(renderedPage);
        return true;
      }
      return false;
    });
    surveyInformationDispatch({ type: 'setActualPage', payload: { actualPage: newActiveStep } });
    history.push('/page/' + newActiveStep);
  };

  const handleLastPageNext = () => {
    history.push('/feedback');
  };

  const selectIconClass = (index) => {
    if (index === surveyInformation.actualPage) {
      return classes.activeIcon;
    }
    return surveyInformation.renderedPages[index]?.isCompleted
      ? classes.completedIcon
      : classes.stepperIcon;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: '1000px',
          paddingTop: '8px',
          justifyContent: 'center',
        }}
      >
        {withStepButtons && (
          <Stepper
            nonLinear
            activeStep={surveyInformation.actualPage}
            connector={null}
            className={classes.stepper}
          >
            {pageDefinitions.map(
              (pageDefinition, index) =>
                pageDefinition.renderPage && (
                  <Step
                    key={index}
                    completed={surveyInformation.renderedPages[index]?.isCompleted ?? false}
                    classes={{ root: classes.step, horizontal: classes.stepHorizontal }}
                  >
                    <Tooltip
                      title={pageDefinitions[index]?.pageHeading[i18n.resolvedLanguage] ?? ''}
                      classes={{ tooltip: classes.tooltip }}
                    >
                      <StepButton
                        color="inherit"
                        onClick={handleStep(index)}
                        classes={{ root: classes.stepButton }}
                      >
                        <StepLabel
                          StepIconProps={{ classes: { root: selectIconClass(index) } }}
                        ></StepLabel>
                      </StepButton>
                    </Tooltip>
                  </Step>
                )
            )}
          </Stepper>
        )}
        {withNavigationButton && (
          <div>
            <RoundButton
              style={{ marginLeft: '10px', marginRight: '12px', marginTop: '20px' }}
              variant="outlined"
              color="secondary"
              fontColor="#C2CADB"
              hoverFontColor="#51504E"
              hoverBackgroundColor="#C2CADB"
              onClick={surveyInformation.actualPage === 0 ? handleFirstPageBack : handleBack}
            >
              {t('buttons.back')}
            </RoundButton>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={isLastStep() && !allStepsCompleted() ? t('questionPages.buttonInfotext') : ''}
            >
              <div style={{ display: 'inline-block' }}>
                <RoundButton
                  style={{ marginRight: '10px', marginLeft: '12px', marginTop: '20px' }}
                  fontColor="#51504E"
                  hoverFontColor="#FFFFFF"
                  color="primary"
                  variant="contained"
                  disabled={isLastStep() && !allStepsCompleted()}
                  onClick={isLastStep() ? handleLastPageNext : handleNext}
                >
                  {t('buttons.next')}
                </RoundButton>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
}
export default ProgressBar;
