import axios from 'axios';

export const getCurrentUser = (accessToken) => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  };
  return axios.get(`/api/v1/currentUser`, options);
};
