import { Button, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { properties } from '../../properties';
const style = makeStyles(() => ({
  footer: {
    position: 'relative',
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#2a4679',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
  },
  footerContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.3em',
  },
  linkButton: {
    color: '#f4f4f4',
    paddingBottom: 0,
    padding: 0,
    fontSize: '1em',
    minWidth: 0,
    '&:hover': {
      color: '#93F100',
    },
  },
  linkButtonText: {
    fontSize: '0.65em',
  },
}));
export default function Footer() {
  const classes = style();
  const { t, i18n } = useTranslation();

  return (
    <footer className={classes.footer}>
      <div className={classes.footerContainer}>
        <Button
          classes={{
            root: classes.linkButton,
            label: classes.linkButtonText,
          }}
          onClick={() => {
            const newWindow = window.open(properties.syngenioUrl);
            newWindow.opener = null;
          }}
        >
          SYNGENIO AG © {new Date().getFullYear()}
        </Button>
        <Button
          classes={{
            root: classes.linkButton,
            label: classes.linkButtonText,
          }}
          onClick={() => {
            const newWindow = window.open(
              i18n.resolvedLanguage === 'en'
                ? properties.gsdPrivacyPolicyEn
                : properties.gsdPrivacyPolicyDe
            );
            newWindow.opener = null;
          }}
        >
          | {t('footer.privacyPolicy')}
        </Button>
        <Button
          classes={{
            root: classes.linkButton,
            label: classes.linkButtonText,
          }}
          onClick={() => {
            const newWindow = window.open(properties.syngenioGtc);
            newWindow.opener = null;
          }}
        >
          | {t('footer.gtc')}
        </Button>
        <Button
          classes={{
            root: classes.linkButton,
            label: classes.linkButtonText,
          }}
          onClick={() => {
            const newWindow = window.open(
              i18n.resolvedLanguage === 'en' ? properties.gsdImprintEn : properties.gsdImprintDe
            );
            newWindow.opener = null;
          }}
        >
          | {t('footer.imprint')}
        </Button>
      </div>
    </footer>
  );
}
