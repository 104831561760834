import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.css';
import AuthenticationContextProvider from './common/providers/AuthenticationContextProvider';
import CalculationContextProvider from './common/providers/CalculationContextProvider';
import GseConfigurationContextProvider from './common/providers/GseConfigurationContextProvider';
import SnackbarPropsContextProvider from './common/providers/SnackbarPropsContextProvider';
import SurveyInformationContextProvider from './common/providers/SurveyInformationContextProvider';
import UserDataContextProvider from './common/providers/UserDataContextProvider';
import { AxiosInterception } from './components/axiosInterception/AxiosInterception';
import CalculationResultHistoryPage from './components/calculationResultHistoryPage/CalculationResultHistoryPage';
import CustomizedSnackbar from './components/customizedSnackbar/CustomizedSnackbar';
import DownloadPage from './components/downloadPage/DownloadPage';
import Footer from './components/footer/Footer';
import Grade from './components/grade/Grade';
import GsdAuthentication from './components/gsdAuthentication/GsdAuthentication';
import GsdLabel from './components/gsdLabel/GsdLabel';
import Homepage from './components/homepage/Homepage';
import IntroductionPage from './components/introductionPage/IntroductionPage';
import LoadingSpinnerSmall from './components/loadingSpinners/LoadingSpinnerSmall';
import ScrollToTop from './components/scrollToTop/ScrollToTop';
import SlideButtons from './components/slideButtons/SlideButtons';
import SurveyForm from './components/surveyForm/SurveyForm';
import SyngenioLogo from './components/syngenioLogo/SyngenioLogo';
import UserFeedbackPage from './components/userFeedbackPage/UserFeedbackPage';
import RestrictedRoute from './routes/RestrictedRoute';

const theme = createTheme({
  palette: {
    primary: {
      main: '#93F100',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFFFFF',
      // contrastText: '#7184A9',
    },
  },
});

function App() {
  const [surveyWasStarted, setSurveyWasStarted] = useState(false);
  const [licenseType, setLicenseType] = useState('default');

  return (
    <div className="App">
      <Router>
        <ThemeProvider theme={theme}>
          <div className="content-container">
            <UserDataContextProvider>
              <AuthenticationContextProvider>
                <SnackbarPropsContextProvider>
                  <Suspense fallback={<LoadingSpinnerSmall />}>
                    <AxiosInterception>
                      <Suspense fallback={<LoadingSpinnerSmall />}>
                        <GsdLabel />
                      </Suspense>
                      <Suspense fallback={<LoadingSpinnerSmall />}>
                        <GsdAuthentication />
                      </Suspense>
                      <GseConfigurationContextProvider>
                        <ScrollToTop>
                          <Switch>
                            <Route default exact path="/home">
                              <Suspense fallback={<LoadingSpinnerSmall />}>
                                <Homepage
                                  setSurveyWasStarted={setSurveyWasStarted}
                                  setLicenseType={setLicenseType}
                                />
                              </Suspense>
                            </Route>
                            <Route exact path="/introductionPage">
                              <Suspense fallback={<LoadingSpinnerSmall />}>
                                <IntroductionPage />
                              </Suspense>
                            </Route>
                            <Route exact path="/downloadPdf/:uuid">
                              <Suspense fallback={<LoadingSpinnerSmall />}>
                                <DownloadPage />
                              </Suspense>
                            </Route>
                            <SurveyInformationContextProvider>
                              <CalculationContextProvider>
                                <RestrictedRoute
                                  surveyWasStarted={surveyWasStarted}
                                  exact
                                  path="/page/:index"
                                >
                                  <SurveyForm />
                                </RestrictedRoute>
                                <RestrictedRoute
                                  surveyWasStarted={surveyWasStarted}
                                  exact
                                  path="/calculationResultHistoryPage"
                                >
                                  <CalculationResultHistoryPage />
                                </RestrictedRoute>
                                <RestrictedRoute
                                  surveyWasStarted={surveyWasStarted}
                                  exact
                                  path="/feedback"
                                >
                                  <UserFeedbackPage />
                                </RestrictedRoute>
                                <RestrictedRoute
                                  surveyWasStarted={surveyWasStarted}
                                  exact
                                  path="/grade"
                                >
                                  <Grade licenseType={licenseType} />
                                </RestrictedRoute>
                              </CalculationContextProvider>
                            </SurveyInformationContextProvider>
                          </Switch>
                          <Suspense fallback={<LoadingSpinnerSmall />}>
                            <SlideButtons />
                          </Suspense>
                        </ScrollToTop>
                        <CustomizedSnackbar />
                      </GseConfigurationContextProvider>
                    </AxiosInterception>
                  </Suspense>
                </SnackbarPropsContextProvider>
              </AuthenticationContextProvider>
            </UserDataContextProvider>
            <SyngenioLogo />
          </div>
          <footer>
            <Suspense fallback={<LoadingSpinnerSmall />}>
              <Footer />
            </Suspense>
          </footer>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
