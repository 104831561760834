import { createContext, useMemo, useState } from 'react';

export const authenticationContext = createContext([{}, () => {}]);

export default function AuthenticationContextProvider({ children }) {
  const [authenticationState, setAuthenticationState] = useState({ isAuthenticated: false });

  const autheticationContextProviderValue = useMemo(
    () => [authenticationState, setAuthenticationState],
    [authenticationState]
  );

  return (
    <authenticationContext.Provider value={autheticationContextProviderValue}>
      {children}
    </authenticationContext.Provider>
  );
}
