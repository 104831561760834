import validator from 'validator';

const UserDataValidation = {
  checkInputDataIsCorrect: function (gseConfiguration, userData, setSnackbarProps, t) {
    if (!gseConfiguration) {
      setSnackbarProps({
        message: t('alerts.backendConnection'),
        severity: 'error',
        duration: 6000,
      });
      return false;
    } else if (!validator.isEmail(userData.email)) {
      setSnackbarProps({
        message: t('alerts.validEmail'),
        severity: 'error',
        duration: 6000,
      });
      return false;
    } else if (
      userData.applicationName === '' ||
      userData.userName === '' ||
      userData.companyName === ''
    ) {
      setSnackbarProps({
        message: t('alerts.validInputFields'),
        severity: 'error',
        duration: 6000,
      });
      return false;
    } else if (!userData.privateDataAgreement) {
      setSnackbarProps({
        message: t('alerts.dataPrivacyAgreement'),
        severity: 'error',
        duration: 6000,
      });
      return false;
    }
    setSnackbarProps(null);
    return true;
  },
};

export default UserDataValidation;
