import { makeStyles, Typography, useTheme } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import playLight from './playLight.svg';
import stepForwardLight from './stepForwardLight.svg';

const style = makeStyles((theme) => ({
  iconBox: {
    '& .MuiButtonBase-root': {
      color: '#C2CADB',
      '&:disabled': {
        color: '#C2CADB',
        opacity: '0.5',
      },
    },
  },
  stepForwardLightIcon: {
    width: '15px',
    height: '22px',
    filter: 'invert(1)',
  },
  playLightIcon: {
    width: '13px',
    height: '18px',
    filter: 'invert(1)',
  },
}));

function CalculationResultTableActions(props) {
  const classes = style();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const { t } = useTranslation();

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const pageCountDisplay = () => {
    if (count === 0) {
      return '0-0 ' + t('calculationResultHistoryPage.pagination') + ' 0';
    }
    if (page * rowsPerPage + rowsPerPage >= count) {
      return (
        page * rowsPerPage +
        1 +
        '-' +
        count +
        ' ' +
        t('calculationResultHistoryPage.pagination') +
        ' ' +
        count
      );
    }
    return (
      page * rowsPerPage +
      1 +
      '-' +
      (page * rowsPerPage + rowsPerPage) +
      ' ' +
      t('calculationResultHistoryPage.pagination') +
      ' ' +
      count
    );
  };

  return (
    <Box style={{ display: 'flex', alignItems: 'center', ml: 2.5 }} className={classes.iconBox}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        style={{ padding: '2px' }}
      >
        {theme.direction === 'rtl' ? (
          <img alt="" src={stepForwardLight} className={classes.stepForwardLightIcon} />
        ) : (
          <img
            alt=""
            src={stepForwardLight}
            className={classes.stepForwardLightIcon}
            style={{ transform: 'matrix(-1, 0, 0, -1, 0, 0)' }}
          />
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        style={{ paddingLeft: '5px' }}
      >
        {theme.direction === 'rtl' ? (
          <img alt="" src={playLight} className={classes.playLightIcon} />
        ) : (
          <img
            alt=""
            src={playLight}
            className={classes.playLightIcon}
            style={{ transform: 'matrix(-1, 0, 0, -1, 0, 0)' }}
          />
        )}
      </IconButton>
      <Typography
        noWrap
        display="inline"
        style={{ fontSize: '14px', color: '#FFFFFF', width: '110px', textAlign: 'center' }}
      >
        {pageCountDisplay()}
      </Typography>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        style={{ paddingRight: '5px' }}
      >
        {theme.direction === 'rtl' ? (
          <img
            alt=""
            src={playLight}
            className={classes.playLightIcon}
            style={{ transform: 'matrix(-1, 0, 0, -1, 0, 0)' }}
          />
        ) : (
          <img alt="" src={playLight} className={classes.playLightIcon} />
        )}
      </IconButton>
      <IconButton
        className={classes.iconButton}
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        style={{ padding: '2px' }}
      >
        {theme.direction === 'rtl' ? (
          <img
            alt=""
            src={stepForwardLight}
            className={classes.stepForwardLightIcon}
            style={{ transform: 'matrix(-1, 0, 0, -1, 0, 0)' }}
          />
        ) : (
          <img alt="" src={stepForwardLight} className={classes.stepForwardLightIcon} />
        )}
      </IconButton>
    </Box>
  );
}

export default CalculationResultTableActions;
