import { makeStyles } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useSurveyInformation from '../../common/hooks/useSurveyInformation';
import useUserData from '../../common/hooks/useUserData';
import Header from '../header/Header';
import RoundButton from '../roundButton/RoundButton';

const style = makeStyles((theme) => ({
  root: { color: '#7184A9', maxWidth: '500px', width: '80%', marginTop: '3%' },
  thumb: { '&:hover': { color: '#FFFFFF' } },
  active: { color: '#FFFFFF' },
  valueLabel: { color: 'transparent', fontSize: '0.8em' },
  textStyle: {
    color: '#FFFFFF',
    width: '90%',
  },
  textArea: {
    backgroundColor: '#d5dfef',
    color: '#000',
    fontSize: 'large',
    borderRadius: '5px',
    border: 'none',
    width: '90%',
    maxWidth: '700px',
    '&::placeholder': {
      fontFamily: 'Calibri, sans-serif',
      fontSize: '0.8em',
      color: '#51504E',
      opacity: '0.8',
    },
  },
}));

function UserFeedbackPage() {
  const classes = style();
  const history = useHistory();
  const [userData, setUserData] = useUserData();
  const [userValuation, setUserValuation] = useState(4);
  const [surveyInformation] = useSurveyInformation();
  const { t } = useTranslation();

  const valueLabelFormat = (value) => {
    const feedbackOptions = [
      t('feedbackPage.feedbackOptions.notGood'),
      t('feedbackPage.feedbackOptions.lessGood'),
      t('feedbackPage.feedbackOptions.satisfactory'),
      t('feedbackPage.feedbackOptions.good'),
      t('feedbackPage.feedbackOptions.veryGood'),
    ];
    return `${feedbackOptions[value]}`;
  };

  const handleTextAreaChange = (event) => {
    setUserData((prevState) => ({ ...prevState, userFeedback: event.target.value }));
  };

  const handleClickGradingPage = () => {
    setUserData((prevState) => ({ ...prevState, userValuation: userValuation }));
    history.push('/grade');
  };

  const handleClickPreviousPage = () => {
    history.push('/page/' + surveyInformation.actualPage);
  };

  const handleSliderChange = (event, newValue) => {
    setUserValuation(newValue);
  };

  return (
    <div>
      <Header></Header>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <p className={classes.textStyle}>{t('feedbackPage.title')}</p>
        <TextareaAutosize
          value={userData.userFeedback}
          onChange={handleTextAreaChange}
          className={classes.textArea}
          minRows={8}
          style={{ padding: '10px' }}
          placeholder={t('feedbackPage.gseFeedback') + '...'}
          aria-label="Feedback"
        />
        <p
          className={classes.textStyle}
          id="discrete-slider"
          style={{ color: '#FFFFFF', marginTop: '40px' }}
        >
          {t('feedbackPage.rateGse')}
        </p>
        <Slider
          className={classes.slider}
          style={{ marginTop: '40px' }}
          classes={{
            root: classes.root,
            active: classes.active,
            thumb: classes.thumb,
            valueLabel: classes.valueLabel,
          }}
          getAriaValueText={valueLabelFormat}
          valueLabelFormat={valueLabelFormat}
          marks
          value={userValuation}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="on"
          step={1}
          min={0}
          max={4}
          onChange={handleSliderChange}
        ></Slider>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }}>
        <RoundButton
          style={{ marginTop: '20px', marginBottom: '2%', marginRight: '10px', marginLeft: '10px' }}
          hoverFontColor="#51504E"
          hoverBackgroundColor="#C2CADB"
          color="secondary"
          variant="outlined"
          onClick={handleClickPreviousPage}
        >
          {t('buttons.back')}
        </RoundButton>
        <RoundButton
          style={{ marginTop: '20px', marginRight: '10px', marginLeft: '10px' }}
          fontColor="#51504E"
          hoverFontColor="#FFFFFF"
          maxMediaDisplayWidth="361px"
          smallDisplayHeight="3.3em"
          smallDisplayWidth="15em"
          onClick={handleClickGradingPage}
          color="primary"
          variant="contained"
        >
          {t('buttons.efficiencyClass')}
        </RoundButton>
      </div>
    </div>
  );
}

export default UserFeedbackPage;
