import { Tooltip, makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';

const style = makeStyles((_theme) => ({
  tooltip: {
    fontSize: 'medium',
  },
  tooltipIcon: {
    color: '#d5dfef',
    fontSize: '1em',
  },
}));

export default function WideDisplayTooltip({ tooltipTitle, ...props }) {
  const classes = style();
  return (
    <Tooltip
      classes={{ tooltip: classes.tooltip }}
      style={props.style}
      disableFocusListener
      disableTouchListener
      title={tooltipTitle}
      placement="left-end"
      arrow
    >
      <InfoIcon className={classes.tooltipIcon} />
    </Tooltip>
  );
}
