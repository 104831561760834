import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const style = makeStyles((_theme) => ({
  loadingSpinner: {
    marginTop: '10px',
  },
}));

function LoadingSpinnerSmall() {
  const classes = style();

  return (
    <CircularProgress
      className={classes.loadingSpinner}
      thickness={3}
      color={'primary'}
      size="3rem"
      disableShrink={false}
      variant="indeterminate"
    ></CircularProgress>
  );
}

export default LoadingSpinnerSmall;
