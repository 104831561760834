const calculationVariableAnswerIsEqualWithConditionAnswer = (condition, calculationVariables) => {
  let answerValueToCompare = calculationVariables[condition.targetVariable].answerValue;
  answerValueToCompare = Number.isInteger(answerValueToCompare)
    ? answerValueToCompare + '.0'
    : answerValueToCompare + '';

  return condition.answerTexts && condition.answerTexts.length !== 0
    ? condition.answerTexts.includes(
        calculationVariables[condition.targetVariable].answerText?.de
      ) || condition.answerTexts.includes(answerValueToCompare)
    : true;
};

const checkAnswerValueRangeCondition = (condition, calculationVariables) => {
  return condition.answerValueRangeIncl && condition.answerValueRangeIncl.length !== 0
    ? calculationVariables[condition.targetVariable].answerValue >=
        condition.answerValueRangeIncl[0] &&
        calculationVariables[condition.targetVariable].answerValue <=
          condition.answerValueRangeIncl[1]
    : true;
};

const checkTargetVariableCondition = (condition, calculationVariables) => {
  return !calculationVariables[condition.targetVariable].behavesLikeNoAnswer;
};

export const renderQuestionByCondition = (condition, calculationVariables) => {
  return (
    calculationVariableAnswerIsEqualWithConditionAnswer(condition, calculationVariables) &&
    checkAnswerValueRangeCondition(condition, calculationVariables) &&
    checkTargetVariableCondition(condition, calculationVariables)
  );
};

const updateConditionalCalculationVariableWithEmptyAnswer = (
  i18n,
  setAnswerInCalculationVariables,
  question
) => {
  let noAnswer = {};
  i18n.languages.forEach((language) => {
    noAnswer = { ...noAnswer, [language]: i18n.getFixedT(language)('questionPages.noAnswer') };
  });
  setAnswerInCalculationVariables(noAnswer, question.defaultValue, true, question.targetVariable);
};

const shouldQuestionRender = (question, calculationVariables) => {
  let renderQuestion = true;
  question.questionConditions?.some((questionCondition) => {
    if (!renderQuestionByCondition(questionCondition, calculationVariables)) {
      renderQuestion = false;
      return true;
    }
    return false;
  });
  return renderQuestion;
};

const QuestionConditionsService = {
  updateConditionalQuestions: function (
    pageDefinitions,
    calculationVariables,
    gseConfigurationDispatch,
    setAnswerInCalculationVariables,
    i18n
  ) {
    pageDefinitions.forEach((pageDefinition, pageDefinitionIndex) => {
      pageDefinition.questions.forEach((question, questionIndex) => {
        let renderQuestion = shouldQuestionRender(question, calculationVariables);
        if (!renderQuestion) {
          updateConditionalCalculationVariableWithEmptyAnswer(
            i18n,
            setAnswerInCalculationVariables,
            question
          );
        }
        gseConfigurationDispatch({
          type: 'setRenderQuestion',
          payload: {
            pageDefinitionIndex: pageDefinitionIndex,
            questionIndex: questionIndex,
            renderQuestion: renderQuestion,
          },
        });
      });
    });
  },
};

export default QuestionConditionsService;
