/* eslint-disable prettier/prettier */
import React, { useMemo, useReducer } from 'react';

function reducer(state, action) {
  const { type, payload } = action;
  let newPageDefinitions = [];
  if (state) {
    newPageDefinitions = [...state.pageDefinitions];
  }
  switch (type) {
    case 'setLanguage':
      return {
        ...state,
        language: payload.language,
      };
    case 'setRenderPage':
      if (payload.renderPage !== state.pageDefinitions[payload.pageDefinitionIndex].renderPage) {
        newPageDefinitions[payload.pageDefinitionIndex] = {
          ...state.pageDefinitions[payload.pageDefinitionIndex],
          renderPage: payload.renderPage,
        };
        return {
          ...state,
          pageDefinitions: newPageDefinitions,
        };
      }
      return state;
    case 'setRenderQuestion':
      if (
        payload.renderQuestion !==
        state.pageDefinitions[payload.pageDefinitionIndex].questions[payload.questionIndex]
          .renderQuestion
      ) {
        newPageDefinitions[payload.pageDefinitionIndex].questions[payload.questionIndex] = {
          ...state.pageDefinitions[payload.pageDefinitionIndex].questions[payload.questionIndex],
          renderQuestion: payload.renderQuestion,
        };
        return {
          ...state,
          pageDefinitions: newPageDefinitions,
        };
      }
      return state;
    case 'setGseConfiguration':
      return payload;
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
}

export const gseConfigurationContext = React.createContext();

export default function GseConfigurationContextProvider({ children }) {
  const [gseConfigurationState, gseConfigurationDispatch] = useReducer(reducer);

  const gseConfigurationProviderValue = useMemo(
    () => [gseConfigurationState, gseConfigurationDispatch],
    [gseConfigurationState]
  );

  return (
    <gseConfigurationContext.Provider value={gseConfigurationProviderValue}>
      {children}
    </gseConfigurationContext.Provider>
  );
}
