import { ClickAwayListener, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import GseHomeIcon from '../../GSD-verifiedBySyngenioAG-light-RGB.svg';
import { properties } from '../../properties';

const style = makeStyles(() => ({
  gseHomeIconContainer: {
    position: 'absolute',
    left: '38px',
    top: '18px',
    display: 'flex',
    '@media (max-width: 767px)': {
      display: 'none',
    },
  },
  gseHomeIcon: {
    width: '105px',
    height: 'auto',
  },
  tooltip: {
    fontSize: 'medium',
  },
  tooltipIcon: {
    marginLeft: '-27px',
    color: '#d5dfef',
    fontSize: '1em',
    opacity: '0.3',
  },
}));

function GsdLabel() {
  const classes = style();
  const [open, setOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={classes.gseHomeIconContainer}>
        <IconButton
          onClick={() => {
            const newWindow = window.open(
              i18n.resolvedLanguage === 'en' ? properties.gsdLabelEn : properties.gsdLabelDe
            );
            newWindow.opener = null;
          }}
        >
          <img src={GseHomeIcon} alt="syngenio-logo" className={classes.gseHomeIcon} />
        </IconButton>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          disableFocusListener
          disableTouchListener
          onClose={handleTooltipClose}
          open={open}
          title={t('gsdLabel')}
          placement="right-end"
          arrow
        >
          <InfoIcon
            className={classes.tooltipIcon}
            onClick={handleTooltipOpen}
            onMouseOver={handleTooltipOpen}
          />
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
}

export default GsdLabel;
