import { Button, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import useAuthentication from '../../common/hooks/useAuthentication';
import useGseConfiguration from '../../common/hooks/useGseConfiguration';
import useSnackbar from '../../common/hooks/useSnackbar';
import useUserData from '../../common/hooks/useUserData';
import GseConfigurationService from '../../service/gesConfigurationService/GseConfigurationService';
import UserDataValidation from '../../service/userDataValidation/UserDataValidation';
import DataprivacyCheckbox from '../dataprivacyCheckbox/DataprivacyCheckbox';
import LoadingSpinnerSmall from '../loadingSpinners/LoadingSpinnerSmall';
import RoundButton from '../roundButton/RoundButton';

const style = makeStyles((theme) => ({
  input: {
    color: '#C2CADB',
    fontSize: '1.8em',
  },
  inputLabel: {
    fontSize: '0.8em',
    color: '#FFFFFF',
  },
  textField: {
    '& .MuiInput-underline': {
      '&:before, &:after, &:hover:not(.Mui-disabled):before': {
        borderBottom: '1px solid #7184A9',
      },
    },
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: '0.5%',
    width: '260px',
  },
  textStyle: {
    color: '#FFFFFF',
    textAlign: 'center',
    maxWidth: '800px',
    width: '80%',
  },
  titleText: {
    textTransform: 'uppercase',
    color: '#F4F4F4',
    textAlign: 'center',
    marginTop: '9%',
  },
}));

function Homepage({ setSurveyWasStarted, setLicenseType }) {
  const classes = style();
  const [, setSnackbarProps] = useSnackbar();
  const [userData, setUserData] = useUserData();
  const [isEmailEntered, setIsEmailEntered] = useState(true);
  const [gseConfiguration, gseConfigurationDispatch] = useGseConfiguration();
  const { t, i18n } = useTranslation();
  let history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const licenseType = query.get('licenseType');
  const [authentication] = useAuthentication();
  const [loadingGseConfiguration, setLoadingGseConfiguration] = useState(true);

  useEffect(() => {
    setSurveyWasStarted(false);
    if (!gseConfiguration) {
      localStorage.removeItem('calculationVariables');
      localStorage.removeItem('surveyInformation');
      localStorage.removeItem('calculationResult');
    }
  }, [setSurveyWasStarted, gseConfiguration]);

  useEffect(() => {
    if (isEmailEntered) {
      setLoadingGseConfiguration(true);
      GseConfigurationService.fetchGseConfiguration(
        licenseType,
        gseConfigurationDispatch,
        setSnackbarProps,
        t,
        setLoadingGseConfiguration
      );
    }
    setIsEmailEntered(false);
    setLicenseType(licenseType);
  }, [
    isEmailEntered,
    setIsEmailEntered,
    licenseType,
    userData.email,
    gseConfigurationDispatch,
    setLicenseType,
    setSnackbarProps,
    t,
    setLoadingGseConfiguration,
  ]);

  useEffect(() => {
    if (authentication.isAuthenticated) {
      setIsEmailEntered(true);
    }
  }, [authentication.isAuthenticated, setIsEmailEntered]);

  const handleStartButtonClick = () => {
    if (
      UserDataValidation.checkInputDataIsCorrect(gseConfiguration, userData, setSnackbarProps, t)
    ) {
      localStorage.setItem('userData', JSON.stringify(userData));
      setSurveyWasStarted(true);
      history.push('/calculationResultHistoryPage');
    }
  };

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <div>
        <Button onClick={() => changeLanguage('de')} style={{ fontSize: '1em', color: '#FFFFFF' }}>
          de
        </Button>
        <Button onClick={() => changeLanguage('en')} style={{ fontSize: '1em', color: '#FFFFFF' }}>
          en
        </Button>
      </div>
      <h1 className={classes.titleText}>Green Software Expertyzer</h1>
      <h2 className={classes.textStyle} style={{ marginBottom: '0px', marginTop: '12px' }}>
        {t('homepage.title')}
      </h2>
      <p className={classes.textStyle} style={{ marginTop: '12px' }}>
        {t('homepage.pageText')}
      </p>
      <div>
        <RoundButton
          style={{ marginTop: '3%' }}
          fontColor="#C2CADB"
          color="secondary"
          hoverFontColor="#51504E"
          hoverBackgroundColor="#C2CADB"
          variant="outlined"
          onClick={() => {
            history.push('/introductionPage');
          }}
        >
          {t('buttons.description')}
        </RoundButton>
      </div>
      <div style={{ marginTop: '3%' }}>
        <TextField
          value={userData.email}
          label={t('homepage.email') + ' *'}
          id="email-field"
          placeholder="example@gmail.com"
          color="primary"
          className={classes.textField}
          inputProps={{
            className: classes.input,
          }}
          InputLabelProps={{ classes: { root: classes.inputLabel } }}
          onBlur={() => {
            setIsEmailEntered(true);
          }}
          onChange={(emailInput) => {
            setUserData((prevState) => ({
              ...prevState,
              email: emailInput.target.value,
            }));
          }}
        />
        <TextField
          value={userData.applicationName}
          label={t('homepage.applicationName') + ' *'}
          id="applicationName-field"
          placeholder="Anwendungsname"
          className={classes.textField}
          inputProps={{
            className: classes.input,
          }}
          InputLabelProps={{ classes: { root: classes.inputLabel } }}
          FormHelperTextProps={{ style: { color: '#FFFFFF' } }}
          onChange={(applicationName) => {
            setUserData((prevState) => ({
              ...prevState,
              applicationName: applicationName.target.value,
            }));
          }}
        />
      </div>
      <div>
        <TextField
          value={userData.userName}
          label={t('homepage.username') + ' *'}
          id="userName-field"
          placeholder="Benutzername"
          className={classes.textField}
          inputProps={{
            className: classes.input,
          }}
          InputLabelProps={{ classes: { root: classes.inputLabel } }}
          FormHelperTextProps={{ style: { color: '#FFFFFF' } }}
          onChange={(userName) => {
            setUserData((prevState) => ({
              ...prevState,
              userName: userName.target.value,
            }));
          }}
        />
        <TextField
          value={userData.companyName}
          label={t('homepage.companyName') + ' *'}
          id="companyName-field"
          placeholder="Firmenname"
          className={classes.textField}
          inputProps={{
            className: classes.input,
          }}
          InputLabelProps={{ classes: { root: classes.inputLabel } }}
          onChange={(companyName) => {
            setUserData((prevState) => ({
              ...prevState,
              companyName: companyName.target.value,
            }));
          }}
        />
      </div>
      <div style={{ maxWidth: '800px', width: '65%', minWidth: '320px' }}>
        <DataprivacyCheckbox setUserData={setUserData} />
      </div>
      <div>
        <RoundButton
          style={{ marginTop: '35%' }}
          color="primary"
          variant="contained"
          fontColor="#51504E"
          hoverFontColor="#FFFFFF"
          hoverWidth="105px"
          onClick={handleStartButtonClick}
          disabled={loadingGseConfiguration}
        >
          START
        </RoundButton>
      </div>
      {loadingGseConfiguration && <LoadingSpinnerSmall />}
    </div>
  );
}

export default Homepage;
