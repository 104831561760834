import axios from 'axios';

export const getPageDefinition = (licenseType) => {
  const body = { licenseType: licenseType ? licenseType : '' };
  const options = {
    headers: {
      Accept: 'application/json',
    },
  };
  return axios.post(`/api/v1/configsge?licenseType=${licenseType}`, body, options);
};
