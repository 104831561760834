import { Button, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { getCurrentUser } from '../../common/api/CurrentUser';
import { getAuthenticationToken } from '../../common/api/FetchAuthenticationToken';
import { logout } from '../../common/api/Logout';
import useAuthentication from '../../common/hooks/useAuthentication';
import useUserData from '../../common/hooks/useUserData';
import { properties } from '../../properties';
import LoadingSpinner from '../loadingSpinners/LoadingSpinner';

const style = makeStyles((theme) => ({
  loginButton: {
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    fontSize: '0.8em',
    position: 'absolute',
    top: '5px',
    right: '50px',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },
  logoutButton: {
    textTransform: 'none',
    color: theme.palette.primary.contrastText,
    fontSize: '0.8em',
    position: 'absolute',
    top: '25px',
    right: '60px',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent',
    },
  },
  displayName: {
    color: theme.palette.primary.contrastText,
    fontSize: '0.8em',
    position: 'absolute',
    top: '5px',
    right: '20px',
  },
}));

const gsdAuthenticationUrl = () => {
  const client_id = properties.clientId;
  const response_type = 'code';
  const redirect_uri = properties.redirectUri;
  const authenticateUrl = properties.gsdAuthenticateUrl;
  return `${authenticateUrl}?response_type=${response_type}&client_id=${client_id}&redirect_uri=${redirect_uri}`;
};

function GsdAuthentication() {
  const classes = style();
  const { t } = useTranslation();
  const query = new URLSearchParams(useLocation().search);
  const applicationCode = query.get('code');
  const fromQuery = query.get('from');
  const [authentication, setAuthentication] = useAuthentication();
  const [userData, setUserData] = useUserData();
  let history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (fromQuery === 'wordpress') {
      setIsLoading(true);
      const newWindow = window.open(gsdAuthenticationUrl(), '_self');
      newWindow.opener = null;
    }
  }, [fromQuery]);

  useEffect(() => {
    if (applicationCode) {
      setIsLoading(true);
      getAuthenticationToken(applicationCode, properties.redirectUri)
        .then((resp) => {
          setAuthentication((prevState) => ({
            ...prevState,
            accessToken: resp.data.access_token,
          }));
        })
        .catch(() => {
          setIsLoading(false);
        });
      history.push('/');
    }
  }, [history, applicationCode, setAuthentication, setUserData, isLoading]);

  useEffect(() => {
    if (authentication.accessToken) {
      getCurrentUser(authentication.accessToken)
        .then((resp) => {
          setUserData((prevState) => ({
            ...prevState,
            hasPremiumRole: resp.data.hasPremiumRole,
            displayName: resp.data.displayName,
            email: resp.data.userEmail ?? prevState.email,
            userName: resp.data.userLogin ?? prevState.userName,
          }));
          setAuthentication((prevState) => ({
            ...prevState,
            isAuthenticated: true,
          }));
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [authentication.accessToken, setAuthentication, setUserData]);

  const logoutUser = () => {
    if (authentication.accessToken) {
      logout(authentication.accessToken);
    }
    setAuthentication({ isAuthenticated: false });
    history.push('/home');
  };

  return (
    <div>
      {isLoading ? <LoadingSpinner shouldBlockPage={true} /> : null}
      <div style={{ textAlign: 'right' }}>
        {!authentication.isAuthenticated ? (
          <Button
            className={classes.loginButton}
            onClick={() => {
              setIsLoading(true);
              const newWindow = window.open(gsdAuthenticationUrl(), '_self');
              newWindow.opener = null;
            }}
          >
            {t('buttons.login')}
          </Button>
        ) : (
          <div>
            <div className={classes.displayName}>{`${t('greeting')}, ${userData.displayName}`}</div>
            <Button className={classes.logoutButton} onClick={logoutUser}>
              {t('buttons.logout')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default GsdAuthentication;
