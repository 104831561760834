import React, { useState } from 'react';
import { useMemo } from 'react';

export const snackbarPropsContext = React.createContext([{}, () => {}]);

export default function SnackbarPropsContextProvider({ children }) {
  const [snackbarMessage, setSnackbarMessage] = useState(null);

  const snackbarPropsProviderValue = useMemo(
    () => [snackbarMessage, setSnackbarMessage],
    [snackbarMessage]
  );

  return (
    <snackbarPropsContext.Provider value={snackbarPropsProviderValue}>
      {children}
    </snackbarPropsContext.Provider>
  );
}
