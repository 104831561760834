import { downloadPdf } from '../../common/api/DownloadPdf';

const DownloadPdfService = {
  handlePdfDownload: function (uuid, setSnackbarProps, t) {
    downloadPdf(uuid)
      .then((response) => {
        const pdfFile = new Blob([response.data], { type: 'application/pdf' });
        const pdfFileURL = URL.createObjectURL(pdfFile);
        const link = document.createElement('a');
        link.href = pdfFileURL;
        link.setAttribute('download', `Result.pdf`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      })
      .catch(() => {
        setSnackbarProps({
          message: t('alerts.downloadFailed'),
          severity: 'error',
          duration: '6000',
        });
      });
  },
};

export default DownloadPdfService;
