import { makeStyles, useMediaQuery } from '@material-ui/core';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCalculationVariables from '../../common/hooks/useCalculationVariables';
import useSurveyInformation from '../../common/hooks/useSurveyInformation';
import QuestionPageService from '../../service/questionPageService/QuestionPageService';
import Question from '../question/Question';
import SmallDisplayTooltip from '../tooltips/SmallDisplayTooltip';
import WideDisplayTooltip from '../tooltips/WideDisplayTooltip';

const style = makeStyles((theme) => ({
  pageHeading: {
    maxWidth: '900px',
    alignSelf: 'center',
    display: 'flex',
  },
  pageHeadingText: {
    maxWidth: '710px',
    fontWeight: '200',
    textAlign: 'center',
    color: '#FFFFFF',
  },
}));

function Page({ pageDefinition }) {
  const classes = style();
  const [calculationVariables] = useCalculationVariables();
  const [surveyInformation, surveyInformationDispatch] = useSurveyInformation();
  const { t, i18n } = useTranslation();
  const matches = useMediaQuery('(max-width:1200px)');

  useEffect(() => {
    window.onbeforeunload = () => t('alerts.pageLeavingPrompt');
    return () => {
      onbeforeunload = null;
    };
  }, [t]);

  useEffect(() => {
    let pageConditions = QuestionPageService.getQuestionConditionsOfPage(pageDefinition);
    surveyInformationDispatch({
      type: 'setPageConditions',
      payload: { page: surveyInformation.actualPage, pageConditions: pageConditions },
    });
  }, [pageDefinition, surveyInformation.actualPage, surveyInformationDispatch]);

  useEffect(() => {
    QuestionPageService.setPageIsCompleted(
      pageDefinition,
      calculationVariables,
      surveyInformation.actualPage,
      surveyInformationDispatch
    );
  }, [
    pageDefinition,
    calculationVariables,
    surveyInformation.actualPage,
    surveyInformationDispatch,
  ]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className={classes.pageHeading}>
        {pageDefinition.infotext &&
          pageDefinition.infotext[i18n.resolvedLanguage] &&
          (matches ? (
            <SmallDisplayTooltip
              tooltipTitle={pageDefinition.infotext[i18n.resolvedLanguage]}
              style={{ marginTop: '10px' }}
            />
          ) : (
            <WideDisplayTooltip
              tooltipTitle={pageDefinition.infotext[i18n.resolvedLanguage]}
              style={{ marginTop: '10px' }}
            />
          ))}
        <h3 className={classes.pageHeadingText}>
          {pageDefinition.pageHeading[i18n.resolvedLanguage]}
        </h3>
      </div>
      {pageDefinition.questions.map((question) => (
        <Question key={question.targetVariable} question={question}></Question>
      ))}
    </div>
  );
}

export default Page;
