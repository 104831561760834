import React from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, makeStyles } from '@material-ui/core';

const style = makeStyles((_theme) => ({
  spinnerBackground: {
    zIndex: 5,
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(63,55,55,0.8)',
  },
  spinner: {
    zIndex: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  h4: {
    fontWeight: '700',
    display: 'inline',
    fontFamily: 'Calibri',
    color: '#ffffff',
    zIndex: 10,
    marginLeft: '-150px',
  },
}));

function LoadingSpinner({ shouldBlockPage }) {
  const classes = style();
  const { t } = useTranslation();

  return shouldBlockPage ? (
    <div className={classes.spinnerBackground}>
      <div className={classes.spinner} style={{ height: '100vh' }}>
        <CircularProgress
          thickness={3}
          color={'primary'}
          size="20rem"
          disableShrink={false}
          variant="indeterminate"
        ></CircularProgress>
        <h4 className={classes.h4}>{t('alerts.pleaseWaitMsg')}</h4>
      </div>
    </div>
  ) : (
    <div className={classes.spinner}>
      <CircularProgress
        thickness={3}
        color={'primary'}
        size="20rem"
        disableShrink={false}
        variant="indeterminate"
      ></CircularProgress>
      <h4 className={classes.h4}>{t('alerts.pleaseWaitMsg')}</h4>
    </div>
  );
}

export default LoadingSpinner;
