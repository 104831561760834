import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import useGseConfiguration from '../../common/hooks/useGseConfiguration';
import useSurveyInformation from '../../common/hooks/useSurveyInformation';
import QuestionPageService from '../../service/questionPageService/QuestionPageService';
import Header from '../header/Header';
import Page from '../page/Page';
import ProgressBar from '../progressBar/ProgressBar';

function SurveyForm() {
  const [gseConfiguration, gseConfigurationDispatch] = useGseConfiguration();
  const [surveyInformation, surveyInformationDispatch] = useSurveyInformation();
  let history = useHistory();
  const maxPage = parseInt(
    Object.keys(surveyInformation.renderedPages)[
      Object.keys(surveyInformation.renderedPages).length - 1
    ]
  );

  useEffect(() => {
    if (history && !gseConfiguration) {
      return history.push('/home');
    }
  }, [history, gseConfiguration]);

  useEffect(() => {
    const renderedPages = QuestionPageService.updatedRenderPagesByQuestionRenders(
      gseConfiguration?.pageDefinitions
    );
    renderedPages.forEach((renderPage, renderedPageIndex) => {
      gseConfigurationDispatch({
        type: 'setRenderPage',
        payload: { pageDefinitionIndex: renderedPageIndex, renderPage: renderPage },
      });
    });
  }, [gseConfiguration?.pageDefinitions, gseConfigurationDispatch]);

  useEffect(() => {
    let newRenderedPages = QuestionPageService.getPagesThatRender(
      gseConfiguration?.pageDefinitions,
      surveyInformation.renderedPages
    );
    surveyInformationDispatch({
      type: 'setRenderedPages',
      payload: {
        renderedPages: newRenderedPages,
      },
    });
  }, [
    gseConfiguration?.pageDefinitions,
    surveyInformationDispatch,
    surveyInformation.renderedPages,
  ]);

  return (
    <div>
      {gseConfiguration?.pageDefinitions &&
        gseConfiguration.pageDefinitions.map((pageDefinition, pageIndex) => (
          <Route key={pageIndex} exact path={'/page/' + pageIndex}>
            <Header></Header>
            <ProgressBar
              maxPage={maxPage}
              withStepButtons={true}
              pageDefinitions={gseConfiguration.pageDefinitions}
            ></ProgressBar>
            <Page key={pageIndex} pageDefinition={pageDefinition} />
            <ProgressBar
              maxPage={maxPage}
              pageDefinitions={gseConfiguration.pageDefinitions}
              withNavigationButton={true}
            ></ProgressBar>
          </Route>
        ))}
    </div>
  );
}

export default SurveyForm;
