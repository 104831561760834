import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';
import React, { forwardRef } from 'react';
import useSnackbar from '../../common/hooks/useSnackbar';

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  alert: {
    fontSize: '0.7em',
  },
}));

function CustomizedSnackbar() {
  const classes = useStyles();
  const [snackbarProps, setSnackbarProps] = useSnackbar();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarProps(null);
  };

  return (
    <div className={classes.root}>
      <Snackbar
        data-testid="snackbar"
        open={!!snackbarProps}
        autoHideDuration={snackbarProps?.duration ?? null}
        onClose={handleClose}
      >
        {snackbarProps ? (
          <Alert onClose={handleClose} severity={snackbarProps.severity} className={classes.alert}>
            {snackbarProps.message}
          </Alert>
        ) : (
          <div></div>
        )}
      </Snackbar>
    </div>
  );
}

export default CustomizedSnackbar;
