import { makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const style = makeStyles((theme) => ({
  radio: {
    '& svg': {
      fontSize: '1em',
    },
    '& .MuiTypography-root': {
      fontSize: '0.8em',
    },
  },
  formControl: {
    '& .MuiIconButton-root': {
      fontSize: '1em',
    },
  },
}));

export default function RadioAnswer({
  question,
  setAnswerInCalculationVariables,
  calculationVariable,
  removePageCompletions,
}) {
  const [selectedValue, setSelectedValue] = useState('');
  const classes = style();
  const { t, i18n } = useTranslation();

  const getNumericValue = (stringValue) => {
    let numericValue;
    question.possibleAnswers.some((answer) => {
      if (
        answer.answerText[i18n.resolvedLanguage] === stringValue ||
        answer.answerValue === stringValue
      ) {
        numericValue = answer.answerValue;
        return true;
      }
      return false;
    });
    return numericValue;
  };

  const getBehavesLikeNoAnswer = (stringValue) => {
    for (const language of i18n.resolvedLanguage) {
      if (i18n.getFixedT(language)('questionPages.noAnswer') === stringValue) {
        return true;
      }
    }
    for (const answer of question.possibleAnswers) {
      if (
        selectedValue === answer.answerValue ||
        selectedValue === answer.answerText[i18n.resolvedLanguage]
      ) {
        return answer.behavesLikeNoAnswer;
      }
    }
    return false;
  };

  const getAnswerText = (stringValue) => {
    for (const answer of question.possibleAnswers) {
      if (
        answer.answerValue === stringValue ||
        answer.answerText[i18n.resolvedLanguage] === stringValue
      ) {
        const answerResult = answer.answerText ? answer.answerText : answer.answerValue;
        return answerResult;
      }
    }
    let noAnswer = {};
    i18n.languages.forEach((language) => {
      noAnswer = { ...noAnswer, [language]: i18n.getFixedT(language)('questionPages.noAnswer') };
    });
    return noAnswer;
  };

  const handleChange = (event, newValue) => {
    setSelectedValue(newValue);
    setAnswerInCalculationVariables(
      getAnswerText(newValue),
      getNumericValue(newValue) ?? question.defaultValue,
      getBehavesLikeNoAnswer(event.target.value) ?? false
    );
    removePageCompletions();
  };

  useEffect(() => {
    if (calculationVariable?.answerText && calculationVariable.answerText[i18n.resolvedLanguage]) {
      setSelectedValue(calculationVariable.answerText[i18n.resolvedLanguage]);
    } else if (calculationVariable?.answerText && calculationVariable.answerText.num) {
      setSelectedValue(calculationVariable.answerText.num);
    } else if (calculationVariable.answerValue != null) {
      setSelectedValue(calculationVariable.answerValue);
    }
  }, [calculationVariable.answerText, calculationVariable.answerValue, i18n.resolvedLanguage]);

  return (
    <FormControl className={classes.formControl}>
      <RadioGroup row value={selectedValue} onChange={handleChange}>
        {question.possibleAnswers.map((answer, index) => (
          <FormControlLabel
            className={classes.radio}
            style={{
              marginTop: '10px',
              marginRight: '50px',
              color:
                !selectedValue ||
                selectedValue === answer.answerValue ||
                selectedValue === answer.answerText[i18n.resolvedLanguage]
                  ? '#FFFFFF'
                  : '#7184A9',
            }}
            key={answer.answerText[i18n.resolvedLanguage] ?? answer.answerValue}
            value={answer.answerText[i18n.resolvedLanguage] ?? answer.answerValue}
            control={
              <Radio
                style={{
                  color:
                    !selectedValue ||
                    selectedValue === answer.answerValue ||
                    selectedValue === answer.answerText[i18n.resolvedLanguage]
                      ? '#FFFFFF'
                      : '#7184A9',
                }}
              />
            }
            label={answer.answerText[i18n.resolvedLanguage] ?? answer.answerText}
          />
        ))}
        {question.noAnswerIsPossible === true && (
          <FormControlLabel
            className={classes.radio}
            style={{
              marginTop: '10px',
              marginRight: '10px',
              color:
                !selectedValue || selectedValue === t('questionPages.noAnswer')
                  ? '#FFFFFF'
                  : '#7184A9',
            }}
            value={t('questionPages.noAnswer')}
            control={
              <Radio
                style={{
                  color:
                    !selectedValue || selectedValue === t('questionPages.noAnswer')
                      ? '#FFFFFF'
                      : '#7184A9',
                }}
              />
            }
            label={t('questionPages.noAnswer')}
          />
        )}
      </RadioGroup>
    </FormControl>
  );
}
