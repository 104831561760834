import { useMediaQuery } from '@material-ui/core';
import React from 'react';
import SlideButtonsDesktop from '../slideButtonsDesktop/SlideButtonsDesktop';
import SlideButtonsMobile from '../slideButtonsMobile/SlideButtonsMobile';
import { properties } from '../../properties';

function SlideButtons() {
  const matches = useMediaQuery('(min-width:1350px)');

  if (matches) {
    return <SlideButtonsDesktop gsdUrl={properties.gsdMemberschipUrl} />;
  } else {
    return <SlideButtonsMobile gsdUrl={properties.gsdMemberschipUrl} />;
  }
}
export default SlideButtons;
