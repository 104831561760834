import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthentication from '../../common/hooks/useAuthentication';
import useSnackbar from '../../common/hooks/useSnackbar';
import CalculationResultService from '../../service/calculationResultService/CalculationResultService';
import DownloadPdfService from '../../service/downloadService/DownloadService';
import LoadingSpinner from '../loadingSpinners/LoadingSpinner';
import CalculationResultTableActions from './CalculationResultTableActions';
import DownloadIcon from './DownloadIcon.svg';

const style = makeStyles((theme) => ({
  textStyle: {
    textAlign: 'center',
    color: '#FFFFFF',
    width: '95%',
  },
  table: {
    marginTop: '2%',
    backgroundColor: 'transparent',
  },
  tableCell: {
    borderBottom: '1px solid #C2CADB',
    color: '#FFFFFF',
    fontSize: '1.0em',
    paddingRight: '0',
    paddingLeft: '0',
  },
  noResultsTableCell: {
    borderBottom: 'none',
    color: '#FFFFFF',
    fontSize: '0.8em',
    textAlign: 'center',
    paddingRight: '0',
    paddingLeft: '0',
  },
  tableHead: {
    borderBottom: '3px solid #C2CADB',
    paddingBottom: '3%',
    color: '#FFFFFF',
    fontSize: '1.5em',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
  },
  downloadIcon: {
    width: '30px',
    height: '40px',
    marginRight: '10px',
    filter: 'invert(1)',
  },
  downloadButton: {
    fontSize: '1.0em',
    paddingRight: '0',
    borderRadius: '0',
  },
  tablePagination: {
    '& .MuiTypography-root': {
      fontSize: '0',
    },
    overflow: 'visible',
    borderBottom: 'none',
  },
}));

function CalculationResultHistory() {
  const classes = style();
  const [calculationResults, setCalculationResults] = useState([]);
  const [page, setPage] = useState(0);
  const rowsPerPage = 5;
  const [, setSnackbarProps] = useSnackbar();
  const [authentication] = useAuthentication();
  const { t } = useTranslation();
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = Math.max(0, (1 + page) * rowsPerPage - calculationResults.length);
  const [loadingCalculationResults, setLoadingCalculationResults] = useState(true);

  const handleDownloadButtonClick = (uuid) => {
    DownloadPdfService.handlePdfDownload(uuid, setSnackbarProps, t);
  };

  useEffect(() => {
    if (authentication.isAuthenticated) {
      CalculationResultService.fetchCalculationResults(
        setCalculationResults,
        setLoadingCalculationResults
      );
    }
  }, [t, setLoadingCalculationResults, authentication.isAuthenticated]);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  return (
    <div>
      <p className={classes.textStyle}>{t('calculationResultHistoryPage.title')}</p>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        {!loadingCalculationResults ? (
          <TableContainer style={{ width: '90%', maxWidth: '1000px' }}>
            <Table className={classes.table} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell
                    align="left"
                    width="26%"
                    className={classes.tableHead}
                    style={{ paddingLeft: '0' }}
                  >
                    {t('calculationResultHistoryPage.date')}
                  </TableCell>
                  <TableCell align="center" width="10%" className={classes.tableHead}>
                    {t('calculationResultHistoryPage.grade')}
                  </TableCell>
                  <TableCell align="center" width="38%" className={classes.tableHead}>
                    {t('calculationResultHistoryPage.version')}
                  </TableCell>
                  <TableCell align="right" width="26%" className={classes.tableHead}>
                    {t('calculationResultHistoryPage.results')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {calculationResults.length === 0 && (
                  <TableCell className={classes.noResultsTableCell} colSpan={6}>
                    {t('calculationResultHistoryPage.noResults')}
                  </TableCell>
                )}
                {calculationResults
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((calculationResult) => (
                    <TableRow key={calculationResult.uuid}>
                      <TableCell className={classes.tableCell} align="justify">
                        {calculationResult.date}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {calculationResult.grade}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="center">
                        {calculationResult.gseVersion}
                      </TableCell>
                      <TableCell className={classes.tableCell} align="right">
                        <IconButton
                          className={classes.downloadButton}
                          color="secondary"
                          variant="text"
                          onClick={() => {
                            handleDownloadButtonClick(calculationResult.uuid);
                          }}
                        >
                          <img alt="" src={DownloadIcon} className={classes.downloadIcon} />
                          {t('buttons.download')}
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 97.5 * emptyRows }}>
                    <TableCell style={{ borderBottom: 'none' }} colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    className={classes.tablePagination}
                    colSpan={4}
                    rowsPerPageOptions={[rowsPerPage]}
                    count={calculationResults.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    ActionsComponent={CalculationResultTableActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        ) : (
          <div className={classes.textStyle}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  );
}

export default CalculationResultHistory;
