import hash from 'object-hash';
import { getCalculatedGrade } from '../../common/api/CalculateSeg';
import { getCalculationResults } from '../../common/api/CalculationResults';
import { generatePdfAndSendWithEmail } from '../../common/api/SendDownloadLink';

export const mapCalculationVariablesByLanguage = (
  calculationVariablesWithLanguages,
  language,
  t
) => {
  let calculationVariables = {};
  Object.keys(calculationVariablesWithLanguages).forEach((targetVariable) => {
    calculationVariables = {
      ...calculationVariables,
      [targetVariable]: {
        ...calculationVariablesWithLanguages[targetVariable],
        answerText:
          calculationVariablesWithLanguages[targetVariable].answerText[language] ??
          calculationVariablesWithLanguages[targetVariable].answerValue ??
          t('questionPages.noAnswer'),
      },
    };
  });
  return calculationVariables;
};

const CalculationResultService = {
  fetchCalculatedGrade: function (
    calculationVariables,
    configUtc,
    userData,
    uuid,
    setCalculationResult,
    setSnackbarProps,
    t,
    language,
    setLoading
  ) {
    getCalculatedGrade(calculationVariables, configUtc, userData, uuid, language)
      .then((resp) => {
        setCalculationResult(resp.data);
        localStorage.removeItem('calculationVariables');
        localStorage.removeItem('surveyInformation');
        resp.data['calculationVariableHash'] = hash(calculationVariables);
        localStorage.setItem('calculationResult', JSON.stringify(resp.data));
        setLoading((prevState) => ({ ...prevState, isLoadingCalculationResult: false }));
      })
      .catch((error) => {
        setLoading((prevState) => ({ ...prevState, isLoadingCalculationResult: false }));
        setSnackbarProps({
          message: t(`exceptions.${error.response.data.message}`),
          severity: 'error',
          duration: 10000,
        });
      });
  },

  generatePdfAndSendEmail: function (uuid, setSnackbarProps, t, email, language, setLoading) {
    generatePdfAndSendWithEmail(uuid, language, email)
      .then(() => {
        setLoading((prevState) => ({ ...prevState, isLoadingPdfGeneration: false }));
        setSnackbarProps({
          message: `${t('responses.emailSendedSuccessfuly')} ${email}`,
          severity: 'info',
          duration: 6000,
        });
      })
      .catch((error) => {
        setLoading((prevState) => ({ ...prevState, isLoadingPdfGeneration: false }));
        setSnackbarProps({
          message: t(`exceptions.${error.response.data.message}`),
          severity: 'error',
          duration: 10000,
        });
      });
  },

  fetchCalculationResults: function (setCalculationResults, setLoadingCalculationResults) {
    getCalculationResults()
      .then((resp) => {
        setCalculationResults(resp.data);
        setLoadingCalculationResults(false);
      })
      .catch(() => {
        setLoadingCalculationResults(false);
      });
  },
};

export default CalculationResultService;
