import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';

const style = makeStyles((theme) => ({
  table: {
    width: '100%',
    backgroundColor: 'transparent',
  },
  tableCellInfo: {
    borderBottom: 'none',
    fontSize: '1em',
    color: '#FFFFFF',
  },
  tableCellGrade: {
    width: '14%',
    borderBottom: 'none',
    color: '#FFFFFF',
    fontSize: '1em',
    verticalAlign: 'top',
  },
  tableHead: {
    color: '#FFFFFF',
    borderBottom: 'none',
    fontSize: '1em',
  },
}));

function GradeExplanation({ config }) {
  const classes = style();
  const { t, i18n } = useTranslation();

  return (
    <TableContainer style={{ maxWidth: '1121px', width: '97%' }}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell colSpan={2} className={classes.tableHead} align="center">
              <h2>{t('gradingPageDetails.gradeClassification')}</h2>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {config.sgiResultCategories.map((resultCategorie) => (
            <TableRow key={resultCategorie.grade}>
              <TableCell className={classes.tableCellGrade} align="left">
                {t('gradingPageDetails.grade')} {resultCategorie.grade}
              </TableCell>
              <TableCell className={classes.tableCellInfo} align="justify">
                {resultCategorie.infotext[i18n.resolvedLanguage]}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default GradeExplanation;
