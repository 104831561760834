import axios from 'axios';

export const generatePdfAndSendWithEmail = (uuid, language, email) => {
  const pdfGenerationBody = { email: email, uuid: uuid };
  const options = {
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': language,
    },
  };
  return axios.post(`/api/v1/generatepdf`, pdfGenerationBody, options);
};
