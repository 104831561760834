import { makeStyles } from '@material-ui/core';
import React from 'react';
import Button from '@material-ui/core/Button';

const style = (props) =>
  makeStyles((theme) => ({
    buttonStyle: {
      borderRadius: '36px',
      height: props.height ?? '1.7em',
      ['@media (max-width:' + props.maxMediaDisplayWidth + ')']: {
        height: props.smallDisplayHeight,
        width: props.smallDisplayWidth,
        lineHeight: 1.2,
      },
      width: props.width,
      color: props.fontColor,
      fontSize: '1em',
      '&:hover': {
        color: props.hoverFontColor,
        backgroundColor: props.hoverBackgroundColor,
        fontWeight: props.hoverFontWeight,
        width: props.hoverWidth,
      },
      '&:disabled': {
        color: '#818181',
        border: '1px solid #818181',
      },
    },
    buttonTextStyle: {
      fontSize: props.fontSize ?? '1em',
      //letterSpacing and paddingLeft should have the same value, because letterSpacing is not affecting the first letter
      letterSpacing: '1.78px',
      paddingLeft: '1.78px',
    },
    buttonStyleContained: {
      boxShadow: '0px 2px 10px 0px rgb(45 78 136)',
    },
  }));

function RoundButton({ ...props }) {
  const classes = style(props)();

  return (
    <Button
      classes={{
        root: classes.buttonStyle,
        label: classes.buttonTextStyle,
        contained: classes.buttonStyleContained,
      }}
      className={props.className}
      style={props.style}
      onClick={props.onClick}
      color={props.color}
      variant={props.variant}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
}

export default RoundButton;
