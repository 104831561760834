export const checkPageIsCompleted = (pageDefinition, calculationVariables) => {
  let isCompleted = true;
  pageDefinition.questions.some((question) => {
    if (!calculationVariables[question.targetVariable]?.answerd) {
      isCompleted = false;
      return true;
    }
    return false;
  });
  return isCompleted;
};

const QuestionPageService = {
  getQuestionConditionsOfPage: function (pageDefinition) {
    let pageConditions = [];
    pageDefinition.questions.forEach((question) => {
      question.questionConditions?.forEach((questionCondition) => {
        if (
          !pageConditions.some(
            (pageCondition) => pageCondition.targetVariable === questionCondition.targetVariable
          )
        ) {
          questionCondition && pageConditions.push(questionCondition);
        }
      });
    });
    return pageConditions;
  },

  setPageIsCompleted: function (
    pageDefinition,
    calculationVariables,
    actualPage,
    surveyInformationDispatch
  ) {
    let isCompleted = checkPageIsCompleted(pageDefinition, calculationVariables);
    surveyInformationDispatch({
      type: 'setPageIsCompleted',
      payload: { page: actualPage, isCompleted: isCompleted },
    });
  },

  removePageCompletions: function (question, surveyInformation, surveyInformationDispatch) {
    Object.keys(surveyInformation.renderedPages).forEach((renderedPage) => {
      surveyInformation.renderedPages[renderedPage].pageConditions?.forEach((pageCondition) => {
        if (
          parseInt(renderedPage) !== surveyInformation.actualPage &&
          pageCondition.targetVariable === question.targetVariable
        ) {
          surveyInformationDispatch({
            type: 'setPageIsCompleted',
            payload: { page: renderedPage, isCompleted: false },
          });
        }
      });
    });
  },

  updatedRenderPagesByQuestionRenders: function (pageDefinitions) {
    let renderedPages = [];
    pageDefinitions?.forEach((pageDefinition) => {
      let renderQuestions = [];
      pageDefinition.questions.forEach((question) => {
        renderQuestions.push(question.renderQuestion);
      });
      const renderPage = renderQuestions && !renderQuestions.every((el) => el === false);
      renderedPages.push(renderPage);
    });
    return renderedPages;
  },

  getPagesThatRender: function (pageDefinitions, renderedPages) {
    let newRenderedPages = {};
    pageDefinitions?.forEach((pageDefinition, pageDefinitionIndex) => {
      if (pageDefinition.renderPage) {
        newRenderedPages = {
          ...newRenderedPages,
          [pageDefinitionIndex]: {
            isCompleted: renderedPages[pageDefinitionIndex]?.isCompleted ?? false,
            pageConditions: renderedPages[pageDefinitionIndex]?.pageConditions ?? [],
          },
        };
      }
    });
    return newRenderedPages;
  },
};

export default QuestionPageService;
