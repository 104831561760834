import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import useSnackbar from '../../common/hooks/useSnackbar';
import DownloadPdfService from '../../service/downloadService/DownloadService';
import Header from '../header/Header';
import RoundButton from '../roundButton/RoundButton';

const style = makeStyles((theme) => ({
  titleText: {
    color: '#FFFFFF',
    textAlign: 'center',
    fontWeight: 200,
    maxWidth: '900px',
    width: '98%',
  },
  textStyle: {
    color: '#FFFFFF',
    textAlign: 'center',
    margin: 0,
  },
}));

function DownloadPage() {
  const classes = style();
  const params = useParams();
  const [, setSnackbarProps] = useSnackbar();
  const { t } = useTranslation();

  const handleDownload = () => {
    DownloadPdfService.handlePdfDownload(params.uuid, setSnackbarProps, t);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Header />
      <h3 className={classes.titleText} style={{ marginBottom: '2em' }}>
        {t('downloadPage.title')}
      </h3>
      <p className={classes.textStyle} style={{ fontSize: '1.2em' }}>
        {t('downloadPage.pageText.part1')}:
      </p>
      <RoundButton
        style={{ marginTop: '3em' }}
        fontColor="#51504E"
        hoverFontColor="#FFFFFF"
        color="primary"
        variant="contained"
        onClick={() => handleDownload()}
      >
        {t('buttons.analysisResult')}
      </RoundButton>
      <p
        className={classes.textStyle}
        style={{
          marginTop: '40px',
          maxWidth: '1000px',
          width: '85%',
          alignItems: 'Center',
          borderTop: '1px solid #7184A9',
        }}
      >
        <br />
        {t('downloadPage.pageText.part2')}
      </p>
    </div>
  );
}

export default DownloadPage;
