import axios from 'axios';

export const logout = () => {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return axios.get(`/api/v1/logout`, options);
};
