import _ from 'lodash';
import React, { useEffect, useMemo, useReducer, useRef } from 'react';
import { getValueFromLocalStorageItemByKey } from '../../utils/LocalStorageUtils';
import useGseConfiguration from '../hooks/useGseConfiguration';

function init(gseConfiguration, language) {
  const calculationVariables = {};

  const setCalculationVariableQuestionByTargetVariable = (calcVariables, question) => {
    calcVariables[question.targetVariable] = {
      targetVariable: question.targetVariable,
      questionLabel: '',
      behavesLikeNoAnswer: false,
      answerText: {},
      answerd: false,
      answerValue: null,
      userFeedback: '',
    };
  };

  gseConfiguration?.pageDefinitions.forEach((pageDefinition) => {
    pageDefinition.questions.forEach((question) => {
      setCalculationVariableQuestionByTargetVariable(calculationVariables, question);
    });
  });
  return calculationVariables;
}

function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case 'setCalculationVariables':
      return payload;
    case 'setCalculationVariable':
      return {
        ...state,
        [payload.targetVariable]: payload.calculationVariable,
      };
    case 'setAnswerInCalculationVariables':
      if (
        !_.isEqual(payload.answerText, state[payload.targetVariable].answerText) ||
        payload.answerValue !== state[payload.targetVariable].answerValue ||
        payload.behavesLikeNoAnswer !== state[payload.targetVariable].behavesLikeNoAnswer
      ) {
        return {
          ...state,
          [payload.targetVariable]: {
            ...state[payload.targetVariable],
            answerText: payload.answerText,
            answerValue: payload.answerValue,
            behavesLikeNoAnswer: payload.behavesLikeNoAnswer,
            answerd: true,
          },
        };
      }
      return state;
    case 'setQuestionLabelsLanguage':
      payload.pageDefinitions.forEach((pageDefinition) => {
        pageDefinition.questions.forEach((question) => {
          state[question.targetVariable].questionLabel = question.questionLabel[payload.locale];
        });
      });
      return {
        ...state,
      };
    case 'resetCalculationVariableState':
      return init(payload);
    default:
      throw new Error(`Unknown action type: ${type}`);
  }
}

export const calculationVariablesContext = React.createContext();

export default function CalculationContextProvider({ children }) {
  const [gseConfiguration] = useGseConfiguration();
  const [calculationVariables, calculationVariablesDispatch] = useReducer(
    reducer,
    gseConfiguration,
    init
  );
  const calledOnce = useRef();
  const calculationVariableContextProviderValue = useMemo(
    () => [calculationVariables, calculationVariablesDispatch],
    [calculationVariables]
  );

  useEffect(() => {
    if (!calledOnce.current) {
      Object.keys(calculationVariables).forEach((key) => {
        const questionFromLocalStorage = getValueFromLocalStorageItemByKey(
          'calculationVariables',
          key
        );
        calculationVariablesDispatch({
          type: 'setCalculationVariable',
          payload: {
            targetVariable: key,
            calculationVariable: questionFromLocalStorage ?? calculationVariables[key],
          },
        });
      });
    }
    calledOnce.current = true;
  }, [calculationVariables]);

  return (
    <calculationVariablesContext.Provider value={calculationVariableContextProviderValue}>
      {children}
    </calculationVariablesContext.Provider>
  );
}
