import { makeStyles } from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import hash from 'object-hash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import useCalculationVariables from '../../common/hooks/useCalculationVariables';
import useGseConfiguration from '../../common/hooks/useGseConfiguration';
import useSnackbar from '../../common/hooks/useSnackbar';
import useUserData from '../../common/hooks/useUserData';
import CalculationResultService, {
  mapCalculationVariablesByLanguage,
} from '../../service/calculationResultService/CalculationResultService';
import GradeExplanation from '../gradeExplanation/GradeExplanation';
import LoadingSpinnerSmall from '../loadingSpinners/LoadingSpinnerSmall';
import RoundButton from '../roundButton/RoundButton';

const style = makeStyles((_theme) => ({
  labelStyle: {
    width: '30%',
    textAlign: 'left',
    color: '#FFFFFF',
  },
  textStyle: {
    textAlign: 'center',
    color: '#FFFFFF',
    width: '95%',
  },
  collapse: {
    fontSize: '1.2em',
    color: '#FFFFFF',
    '&:hover': {
      color: '#C2CADB',
    },
  },
}));

function Grade({ licenseType }) {
  const classes = style();
  const history = useHistory();
  const [calculationResult, setCalculationResult] = useState({});
  const [userData] = useUserData();
  const [calculationVariablesWithLanguages] = useCalculationVariables();
  const [gseConfiguration] = useGseConfiguration();
  const [collapse, setCollapse] = useState(false);
  const [, setSnackbarProps] = useSnackbar();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState({
    isLoadingCalculationResult: true,
    isLoadingPdfGeneration: true,
  });
  const [uuid] = useState(uuidv4());

  useEffect(() => {
    window.onbeforeunload = () => t('alerts.pageLeavingPrompt');
    return () => {
      onbeforeunload = null;
    };
  }, [t]);

  const setCollapseIcon = () => {
    if (collapse) {
      return (
        <p className={classes.collapse} style={{ marginBottom: '0px' }}>
          <ExpandLessIcon />
          {t('gradingPage.pageText.part4')}
        </p>
      );
    } else {
      return (
        <p className={classes.collapse} style={{ marginBottom: '0px' }}>
          <ChevronRightIcon />
          {t('gradingPage.pageText.part4')}
        </p>
      );
    }
  };

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push('/home');
      }
    };
  }, [history]);

  useEffect(() => {
    let result = JSON.parse(localStorage.getItem('calculationResult'));
    if (
      !result ||
      (calculationVariablesWithLanguages &&
        result['calculationVariableHash'] !== hash(calculationVariablesWithLanguages))
    ) {
      const calculationVariables = mapCalculationVariablesByLanguage(
        calculationVariablesWithLanguages,
        i18n.resolvedLanguage,
        t
      );
      CalculationResultService.fetchCalculatedGrade(
        calculationVariables,
        gseConfiguration.configUtc,
        userData,
        uuid,
        setCalculationResult,
        setSnackbarProps,
        t,
        i18n.resolvedLanguage,
        setLoading
      );
    } else {
      setCalculationResult(result);
      localStorage.removeItem('calculationVariables');
      localStorage.removeItem('surveyInformation');
    }
  }, [
    uuid,
    calculationVariablesWithLanguages,
    setSnackbarProps,
    userData,
    gseConfiguration.configUtc,
    licenseType,
    t,
    i18n.resolvedLanguage,
  ]);

  useEffect(() => {
    if (calculationResult?.results) {
      CalculationResultService.generatePdfAndSendEmail(
        uuid,
        setSnackbarProps,
        t,
        userData.email,
        i18n.resolvedLanguage,
        setLoading
      );
    }
  }, [
    uuid,
    calculationResult?.results,
    i18n.resolvedLanguage,
    setSnackbarProps,
    t,
    userData.email,
  ]);

  if (!calculationResult.results) {
    return (
      <>
        <div>
          {loading.isLoadingCalculationResult && (
            <LoadingSpinnerSmall style={{ marginBottom: '40px' }} />
          )}
        </div>
        <RoundButton
          style={{ marginTop: '20px' }}
          color="secondary"
          fontColor="#F4F4F4"
          hoverFontColor="#51504E"
          hoverBackgroundColor="#C2CADB"
          variant="outlined"
          onClick={() => history.push('/home')}
        >
          {t('buttons.backToHome')}
        </RoundButton>
      </>
    );
  }

  return (
    <>
      <div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <h1 className={classes.textStyle} style={{ marginTop: '5%' }}>
            {t('gradingPage.title')}
          </h1>
          <p
            className={classes.textStyle}
            style={{ marginTop: '0px', maxWidth: '1200px', width: '85%' }}
          >
            {t('gradingPage.pageText.part1')}
          </p>
          <p className={classes.textStyle} style={{ marginBottom: '0px', fontSize: '1.4em' }}>
            {t('gradingPage.pageText.part2')}
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            <p
              className={classes.textStyle}
              style={{
                color: calculationResult.gradeColor,
                font: 'normal normal 300 8em/180px Calibri, sans-serif',
                letterSpacing: '0px',
                textTransform: 'uppercase',
                margin: 0,
              }}
            >
              {calculationResult.grade}
            </p>
          </div>
          <p
            className={classes.textStyle}
            style={{ marginTop: '0px', marginBottom: '0px', fontSize: '1.2em' }}
          >
            {t('gradingPage.pageText.part3')}: {calculationResult.finalScore}
          </p>
          <div
            className={classes.textStyle}
            style={{ paddingTop: '20px' }}
            onClick={() => {
              setCollapse((prevState) => !prevState);
            }}
          >
            {setCollapseIcon()}
          </div>

          <Collapse in={collapse}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <h2 className={classes.textStyle} style={{ marginBottom: '1%' }}>
                {t('gradingPage.pageText.part5')}
              </h2>
              {calculationResult.results.map((result) => (
                <div style={{ textAlign: 'left', width: '80%' }} key={result.targetVariable}>
                  <>
                    <p
                      className={classes.textStyle}
                      style={{ marginBottom: '0px', marginTop: '20px' }}
                    >
                      {result.name + ': ' + result.value}
                    </p>
                    <p className={classes.textStyle} style={{ marginTop: '6px' }}>
                      {result.description}
                    </p>
                  </>
                </div>
              ))}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                marginTop: '2%',
              }}
            >
              <GradeExplanation config={gseConfiguration}></GradeExplanation>
            </div>
          </Collapse>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              marginTop: '1em',
            }}
          >
            <p
              className={classes.textStyle}
              style={{
                marginTop: '2%',
                maxWidth: '1000px',
                width: '80%',
                alignItems: 'Center',
                borderTop: '1px solid #7184A9',
              }}
            >
              <br />
              {t('gradingPage.pageText.part6')}
            </p>
          </div>
        </div>

        <RoundButton
          style={{ marginTop: '20px' }}
          color="secondary"
          fontColor="#F4F4F4"
          hoverFontColor="#51504E"
          hoverBackgroundColor="#C2CADB"
          variant="outlined"
          onClick={() => history.push('/home')}
        >
          {t('buttons.backToHome')}
        </RoundButton>
        <Prompt message={t('alerts.pageLeavingPrompt')} />
      </div>
      {loading.isLoadingPdfGeneration && <LoadingSpinnerSmall />}
    </>
  );
}

export default Grade;
